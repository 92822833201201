import React  from 'react'
import classNames from 'classnames'
import * as icons from 'icons'
import { convertDashesToCamelCase } from 'utils'

import styles from './Icon.module.scss'

type IconProps = {
  type: string;
  className?: string
}

type IconSetType = { [key: string]: ((...args: any[]) => any) | string}
const IconSet: IconSetType = { ...icons }

export const Icon: React.FC<IconProps> = ({ type, className}) => {

    const iconType = convertDashesToCamelCase(type)

    if (!iconType || !IconSet[iconType]) {
      return null
    }

    const IconInner = typeof IconSet[iconType] !== "string" && IconSet[iconType]

  return IconInner ?
      <div className={classNames(styles.icon,`icon-${iconType}`, className)}><IconInner/></div> :
      null
}
