import React from 'react';
import {IonItem, IonList, IonLabel, IonListHeader, IonIcon } from "@ionic/react";
import {CategoryType, MemoryType, PeriodType} from "components/Timeline/utils";
import {getCategoryPik} from "icons/ionic";
import classNames from "classnames";
import styles from "components/PeriodSelect/PeriodSelect.module.scss";

type Props = {
  periods: PeriodType[] | null;
  categories: CategoryType[] | null;
  memories: MemoryType[] | null;
};

const TimelineListView = ({ categories, periods , memories}:Props) => {
  return (
    <div>
      {categories && categories.map((category: CategoryType) => {
        const periodsOf = periods ? periods.filter((period: PeriodType) => period.category === category.id) : undefined;
        const pik = getCategoryPik(category.type as string, null)

        return periodsOf && periodsOf.length ? (
          <React.Fragment key={category.id}>
            <IonListHeader className={classNames(styles.listHeader, `theme-${category.theme}`)}>
              {pik !== null && <IonIcon icon={pik} size="small" color={category.theme} className={styles.colorIcon} />}
              {category.name}
            </IonListHeader>
            <IonList>
              {periodsOf.map((period: PeriodType) => {
                return (
                  <IonItem key={period.id} className={styles.option}>
                    {/*<IonIcon slot="start" icon={getCategoryPik(category.type as string, circle)} size={"small"} color={category.theme} className={styles.colorIcon} />*/}
                    <IonLabel className={classNames(styles.label, `theme-${category.theme}`)}>
                      <h2>{period.name}</h2>
                      {/*{category.type && <p>{t('INPUT.CATEGORY.APPLIED')}{t(`enum:CATEGORY_TYPE.${category.type}`)}</p>}*/}
                    </IonLabel>
                  </IonItem>
                )})}
            </IonList>
          </React.Fragment>
        ) : null})
      }

    </div>
  );
};

export default TimelineListView;