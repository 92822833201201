import React, {useEffect} from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { IonReactHashRouter as IonReactRouter } from '@ionic/react-router';
import { menuController } from '@ionic/core';
import { person, people, settings, send, helpCircle } from 'ionicons/icons';

import {
  Home,
  Settings,
  TimelinePage,
  Feed,
  Details,
  PeriodForm,
  MemoryForm,
  CategoryForm,
  HelpTopic,
  Help
} from "pages";


import SideMenu, { SideMenuOption } from './components/SideMenu';

import {ROUTES} from "config";

import { home, timeline, feed } from 'icons/ionic'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.css';
/* Custom app styles */
import 'styles/index.scss';
import {useDispatch, useSelector} from "react-redux";
import {getContents} from "redux/actions/ContentActions";
import { getLanguageSetting } from "redux/selectors/SettingsSelectors";
import {initTranslations} from "services/i18n";


const menuOptions: SideMenuOption[] = [
  {
    title: 'SIDE.PROFILE',
    url: '/',
    icon: person
  },
  {
    title: 'SIDE.FRIENDS',
    url: '/',
    icon: people
  },
  {
    title: 'SIDE.SETTINGS',
    url: ROUTES.SETTINGS,
    icon: settings
  },
  {
    title: 'SIDE.FEEDBACK',
    url: '/',
    icon: send
  },
  {
    title: 'SIDE.HELP',
    url: ROUTES.HELP,
    icon: helpCircle
  },
];

type Props = {
  ready: boolean;
}

const App: React.FC<Props> = ({ ready}) => {

  const dispatch = useDispatch();
  const language = useSelector(getLanguageSetting)
  
  useEffect(() => {
    initTranslations(language);

    if (ready) {
      dispatch(getContents())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready])

  if (!ready) return null;

  return (
    <IonApp>
      <IonReactRouter>
        <SideMenu options={menuOptions}/>

        <IonRouterOutlet id="main">
          <Route path={ROUTES.SETTINGS} component={Settings} exact={true}/>
          <Route path={`${ROUTES.HELP}/:section/:id`} component={HelpTopic}/>
          <Route path={`${ROUTES.HELP}`} component={Help} exact={true}/>
          <Route path={`${ROUTES.TIMELINE}${ROUTES.PERIOD}`} component={PeriodForm}/>
          <Route path={`${ROUTES.TIMELINE}${ROUTES.MEMORY}`} component={MemoryForm}/>
          <Route path={`${ROUTES.TIMELINE}${ROUTES.CATEGORY}`} component={CategoryForm}/>

          <Route path={[ROUTES.HOME, ROUTES.TIMELINE, ROUTES.FEED]}>
            <IonTabs>
              <IonRouterOutlet id="tabs">
                <Route path={ROUTES.HOME} component={Home} exact={true}/>
                <Route path={ROUTES.TIMELINE} component={TimelinePage} exact={true}/>

                <Route path={ROUTES.FEED} component={Feed} exact={true}/>
                <Route path={`${ROUTES.FEED}${ROUTES.DETAILS}`} component={Details}/>

              </IonRouterOutlet>
              <IonTabBar slot="bottom">
                <IonTabButton tab="home" href={ROUTES.HOME} onClick={() => menuController.enable(true)}>
                  <IonIcon icon={home} size="large"/>
                  {/*<IonLabel>Home</IonLabel>*/}
                </IonTabButton>
                <IonTabButton tab="timeline" href={ROUTES.TIMELINE} onClick={() => menuController.enable(false)}>
                  <IonIcon icon={timeline} size="large"/>
                  {/*<IonLabel>Timeline</IonLabel>*/}
                </IonTabButton>
                <IonTabButton tab="feed" href={ROUTES.FEED} onClick={() => menuController.enable(false)}>
                  <IonIcon icon={feed} size="large"/>
                  {/*<IonLabel>Friends</IonLabel>*/}
                </IonTabButton>
              </IonTabBar>
            </IonTabs>
          </Route>

          <Route path="/" render={() => <Redirect to={ROUTES.HOME}/>} exact={true}/>
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
)};

export default App;
