import {book, build, grid} from "ionicons/icons";
import {ROUTES} from "config";

type Hash = {[index:string]:HelpSectionType}

export type HelpSectionType = {
  id: string;
  topics: HelpTopicType[];
}

export type HelpTopicType = {
  id: string;
  icon?: string;
}

export const getTopicsSection = (sectionId: string):HelpSectionType | undefined => {
  return helpTopics.find(section => section.id === sectionId)
}

export const getTopic = (sectionId: string, topicId: string):HelpTopicType | undefined => {
  const section = getTopicsSection(sectionId);
  return section ? section.topics.find(topic => topic.id === topicId) : section;
}

export const getTopicLink = (sectionId: string, topicId: string): string | undefined => {
  const topic = getTopic(sectionId, topicId);

  return topic ? `${ROUTES.HELP}/${sectionId}/${topicId}` : topic;
}

export const helpTopics: HelpSectionType[] = [
  {
    id: "general",
    topics: [
      {id: 'why_retrospective_matters', icon: book},
      {id: 'scaffold_your_life', icon: build},
      {id: 'change_your_life_perspective', icon: grid}
    ],
  },
  {
    id: "timelines",
    topics: [
      {id: 'special_types'},
      {id: 'blurred_mode'},
    ]
  },
  {
    id: "test1",
    topics: [
      {id: 'test1'},
      {id: 'test2'},
      {id: 'test3'},
      {id: 'test4'},
      {id: 'test5'},
    ]
  },
  {
    id: "test2",
    topics: [
      {id: 'test1'},
      {id: 'test2'},
      {id: 'test3'},
      {id: 'test4'},
      {id: 'test5'},
    ]
  }
]