import React from 'react'
import classNames from 'classnames'

import styles from './Line.module.scss'

type Props = {
  position?: number | string;

  className?: string;
}

export const Line = ({position, className}:Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={classNames(styles.Line, className)}>
      <line fill="none" x1={position} y1="0" x2={position} y2="100%"/>
    </svg>
  )
}