import React from 'react';
import {
  IonHeader,
  IonToolbar,
  IonPage,
  IonTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonBackButton, IonButtons, IonToggle, IonListHeader, IonSelectOption, IonSelect
} from '@ionic/react';
import {useDispatch, useSelector} from "react-redux";
import {setLanguage, setTapticFeedback} from "redux/actions/SettingsActions";
import {ListFooter} from "components/ListFooter";
import {useTranslation} from "react-i18next";
import {LANGUAGES} from "config";
import {getSettingsState} from "redux/selectors/SettingsSelectors";

export const Settings: React.FC = () => {

  const { t } = useTranslation('Settings')
  const dispatch = useDispatch()
  const settings = useSelector(getSettingsState)

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" text={t('BACK')} />
          </IonButtons>
          <IonTitle>{t('TITLE')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="outer-content">
        <IonListHeader>
          {t('INTERFACE')}
        </IonListHeader>
        <IonList>
          <IonItem>
            <IonLabel>{t('INPUT.TAPTIC.TITLE')}</IonLabel>
            <IonToggle checked={settings.taptic} onIonChange={(e) => dispatch(setTapticFeedback(e.detail.checked))}/>
          </IonItem>
        </IonList>
        <ListFooter>
          {t('INPUT.TAPTIC.HINT')}
        </ListFooter>
        <IonList>
          <IonItem>
            <IonLabel>{t('INPUT.LANGUAGE.TITLE')}</IonLabel>
            <IonSelect
              value={settings.language || "auto"}
              okText={t('INPUT.LANGUAGE.OKAY')}
              cancelText={t('INPUT.LANGUAGE.DISMISS')}
              onIonChange={(e) => dispatch(setLanguage(e.detail.value))}
            >
              {Object.keys(LANGUAGES).map(lng => (
                <IonSelectOption value={lng} key={lng}>{t(`INPUT.LANGUAGE.${LANGUAGES[lng]}`)}</IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
        </IonList>

      </IonContent>
    </IonPage>
  );
};
