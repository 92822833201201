import { useState, useEffect, useCallback } from 'react';

interface State {
  startLongPress: boolean;
  nativeEvent: Event | null;
}

export const useLongPress = (callback = (e:Event) => {}, ms = 300) => {
  const [{ startLongPress, nativeEvent }, setStartLongPress] = useState<{startLongPress: boolean, nativeEvent: Event | null}>({ startLongPress: false, nativeEvent: null});

  useEffect(() => {
    let timerId = 0;
    if (startLongPress && nativeEvent) {
      timerId = window.setTimeout(() => callback(nativeEvent), ms);
    } else {
      clearTimeout(timerId);
    }

    return () => {
      clearTimeout(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startLongPress]);

  const start = useCallback((e) => {
    setStartLongPress({startLongPress: true, nativeEvent: e.nativeEvent});
  }, []);
  const stop = useCallback(() => {
    setStartLongPress({startLongPress: false, nativeEvent: null});
  }, []);

  return {
    onMouseDown: start,
    onMouseUp: stop,
    onMouseLeave: stop,
    onTouchStart: start,
    onTouchEnd: stop,
  };
}