import * as React from 'react';
import {HelpSectionType, helpTopics, HelpTopicType} from "./topics";
import {useTranslation} from "react-i18next";
import {IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonText} from "@ionic/react";
import {ROUTES} from "config";
import {book} from "ionicons/icons";

import styles from './Help.module.scss'

type TextProps = {
  section: HelpSectionType | null;
  topic: HelpTopicType | null;
};

export const TopicText: React.FC<TextProps> = ({ section, topic}) => {
  const { t } = useTranslation('help')

  return section && topic && <div className={styles.TopicText}>
    <IonText><h1>{t(`${section.id}.${topic.id}.TITLE`)}</h1></IonText>
    <IonText color="dark">
      <div dangerouslySetInnerHTML={{__html: t(`${section.id}.${topic.id}.TEXT`)}}/>
    </IonText>
  </div>;
};

type SectionProps = {
  section: HelpSectionType;
  exclude?: HelpTopicType;
  title?: string;
}

export const TopicsSection: React.FC<SectionProps> = ({section, title, exclude}) => {
  const { t, i18n } = useTranslation('help');

  return i18n.exists(`help:${section.id}.TITLE`) ?
    <IonList lines="none" key={section.id}>
      <IonListHeader>
        <IonLabel>{title || t(`${section.id}.TITLE`)}</IonLabel>
      </IonListHeader>
      <>
      {section.topics.map((topic: HelpTopicType) => (
        (exclude !== topic && i18n.exists(`help:${section.id}.${topic.id}.TITLE`)) &&
          <IonItem key={topic.id} routerLink={`${ROUTES.HELP}/${section.id}/${topic.id}`} detail>
            <IonIcon slot="start" color="medium" icon={topic.icon || book}/>
            <IonLabel>{t(`${section.id}.${topic.id}.TITLE`)}</IonLabel>
          </IonItem>
      ))}
      </>
    </IonList>
    : null
}


export const TopicsIndex: React.FC = () => {
  return (
    <>
      {helpTopics.map((section: HelpSectionType) => (
        <TopicsSection section={section} key={section.id}/>
      ))}
    </>
  )
};