import React from 'react';
import {
  IonHeader,
  IonToolbar,
  IonPage,
  IonTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonAvatar
} from '@ionic/react';
import {ROUTES} from "config";

export const Feed: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Feed</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
          <IonList>
              <IonItem routerLink={`${ROUTES.FEED}${ROUTES.DETAILS}`}>
                  <IonAvatar slot="start">
                    <img src="/assets/avatar.svg" alt=""/>
                  </IonAvatar>
                  <IonLabel>
                    <h3>Aleksey Karpenko </h3>
                    <p>Added new memory:</p>
                    <p>Consectetur adipiscing elit. Duis ut urna neque.</p>
                  </IonLabel>
              </IonItem>
            <IonItem routerLink={`${ROUTES.FEED}${ROUTES.DETAILS}`}>
                  <IonAvatar slot="start">
                    <img src="/assets/avatar.svg" alt=""/>
                  </IonAvatar>
                  <IonLabel>
                    <h3>Michael Dikun</h3>
                    <p>Added new memory:</p>
                    <p>Consectetur adipiscing elit. Duis ut urna neque.</p>
                  </IonLabel>
              </IonItem>
          </IonList>
      </IonContent>
    </IonPage>
  );
};

