import * as categoriesPik from "icons/ionic/categories";

// Custom Ionic icons to use as <IonIcon icon={...}>
export { default as timeline } from './timeline.svg'
export { default as friends } from './friends.svg'
export { default as home } from './home.svg'
export { default as feed } from './feed.svg'
export { default as circle } from './circle.svg'


//TODO: refactor this naming *facepalm*
// export const timeline = { ios: i_timeline, md: i_timeline}
// export const friends = { ios: i_friends, md: i_friends}
// export const home = { ios: i_home, md: i_home}
// export const feed = { ios: i_feed, md: i_feed}
// export const circle = { ios: i_circle, md: i_circle}

export const getCategoryPik = (categoryType:string, fallback:any) => (categoriesPik as {[index: string]:any})[categoryType as string] || fallback

