export const LANGUAGES: {[index: string]:any} = {
  auto: 'AUTO',
  en:'ENGLISH',
  ru:'RUSSIAN',
  ...(process.env.NODE_ENV === 'development' ? {cimode: 'DEBUG'} : {} )
}

export const TIMELINE: {[index: string]:any} = {
  THEMES: {
    "pastel-pink": "Pastel Pink",
    "chantilly": "Chantilly",
    "french-lilac": "French Lilac",
    "prelude": "Prelude",
    "periwinkle-gray": "Periwinkle Gray",
    "sail": "Sail",
    "french-pass": "French Pass",
    "blizzard-blue": "Blizzard Blue",
    "aqua-island": "Aqua Island",
    "zanah": "Zanah",
    "chrome-white": "Chrome White",
    "feijoa": "Feijoa",
    "tusk": "Tusk",
    "manz": "Manz",
    "lemon-chiffon": "Lemon Chiffon",
    "paris-daisy": "Paris Daisy",
    "buttermilk": "Buttermilk",
    "frangipani": "Frangipani",
    "romantic": "Romantic",
  },
  PERIOD_SIZE: 40,
  CATEGORY_PADDING: 40

}

export const ROUTES: {[index: string]:any} = {
  HOME: "/home",
  TIMELINE: "/timeline",
  FEED: "/feed",
  SETTINGS: "/settings",
  HELP: "/help",

  DETAILS: "/details",
  MEMORY: "/memory",
  PERIOD: "/period",
  CATEGORY: "/category"
}