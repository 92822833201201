import React, {useEffect, useState} from 'react'
import classNames from "classnames";
import {MemoryType, YEAR_TIME, getYearWidth} from "components/Timeline/utils";
import { useLongPress } from "utils";

import styles from './Memory.module.scss'

type Props = {
  memory: MemoryType;
  years: Array<number>;
  zoom: number;
  onMemoryClick: (e:React.MouseEvent) => void;
  onMemoryHold: (e:Event) => void;
  currentMemory: MemoryType | null;
}

type MemoryPosition = {
  left: number,
}


export const getMemoryPosition = (memory: MemoryType, years: Array<number>, zoom: number) => {

  const yearWidth = getYearWidth(zoom);
  const memoryYear = memory.memoryDate.getFullYear();
  const memoryYearFirstDay = new Date(`${memoryYear}-01-01`).getTime();

  const left =
    years.indexOf(memoryYear) * yearWidth +
    (yearWidth * (memory.memoryDate.getTime() - memoryYearFirstDay)) /
    YEAR_TIME;

  return { left };
}

const getMemorySize = (zoom: number, relevance: number):number => {

  //           zoom
  //          1  2  3
  //         ---------
  //      1 | 3  4  5 |
  // rel  2 | 2  3  4 |
  //      3 | 1  2  3 |
  //         ---------

  return relevance ? zoom + 1 + (3 - relevance) : 4;
}

export const Memory = ({
  memory,
  years,
  zoom,
  onMemoryClick,
  onMemoryHold,
  currentMemory
}: Props) => {

  const onHoldWatcher = useLongPress(onMemoryHold, 500);

  const [position, setPeriodPosition] = useState<MemoryPosition | null>(null);

  useEffect(() => {
    setPeriodPosition(getMemoryPosition(memory, years, zoom))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoom, years]);

  return position ? (
    <div
      className={classNames(
        styles.Memory,
        memory.period === null && styles.alone,
        styles[`size-${getMemorySize(zoom, memory.significance)}`]
      )}
      style={{
        left: `${position.left}px`
      }}
      onClick={onMemoryClick}
      {...onHoldWatcher}
    >
      {memory.image && <div className={styles.image} style={{backgroundImage: `url(/photos/${memory.image}.jpg)`}} />}
    </div>
  ) : null
}