import React, {useEffect, useState} from 'react'
import classNames from "classnames";
import { getYearWidth } from "components/Timeline/utils";

import styles from './YearSticker.module.scss'

type Props = {
  years: number[];
  zoom: number;
  scrollLeft: number;
  detailed: boolean;
}

type State = {
  isVisible: boolean;
  currentYear: number | null;
}

const shouldShowYearSticker = (years: number[], zoom: number, scrollLeft: number) => {
  const yearWidth = getYearWidth(zoom);

  const yearScroll = scrollLeft % yearWidth;
  const isVisible = scrollLeft > 0 && yearScroll < yearWidth - 50;
  const currentYearIndex = (scrollLeft - yearScroll) / yearWidth;

  return {
    isVisible,
    currentYear: years[currentYearIndex],
  }
}

export const YearSticker = ({years, zoom, scrollLeft, detailed}: Props) => {

  const [yearSticker, setYearSticker] = useState<State>({
    isVisible: false,
    currentYear: null,
  });


  useEffect(() => {
    setYearSticker(shouldShowYearSticker(years, zoom, scrollLeft))
  }, [years, zoom, scrollLeft]);

  const { isVisible, currentYear } = yearSticker


  return currentYear ? (
    <div
      className={classNames(
        styles.YearsSticker,
        zoom === 2 && styles.zoomed,
        !isVisible && styles.hidden,
        detailed && styles.detailed
      )}
    >
      {currentYear}
    </div>
  ) : null
}