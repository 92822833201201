import {ContentReduxState} from "redux/reducers/contents";
import {CategoryType} from "components/Timeline/utils";

export const processLoadedContent = (state:any, payload:any) => {
  // const { IMAGE_MAPS_ORDER } = getConfig()
  const data = {memories: [], periods: [], categories: [], ...payload} // init fields
  // const videosIndex = buildVideosIndex(data.videos)

  const newState:ContentReduxState = {
    ...state,
    fetching: false,
    memories: data.memories,
    periods: data.periods,
    categories: data.categories.sort((cat1:CategoryType, cat2:CategoryType) => cat1.order < cat2.order ? -1 : 1)
    // topics: fromJS(buildTopicsList(data.topics, videosIndex)),
    // searchTopics: fromJS([]),
    // searchFolders: fromJS([]),
    // searchQuery: '',
    // videosIndex: videosIndex,
    // folders: fromJS(data.folders),
    // foldersIndex: null,
    // contentIndex: null,
    // publications: fromJS(data.publications),
    // imageMaps: sortItemsByFieldInOrder(data.imageMaps, 'title', IMAGE_MAPS_ORDER),
    // heroShots: data.heroShots,
    // fetching: false
  }

  // if (payload.publications && payload.publications.length) {
  //   newState.contentIndex = buildIndex(data.publications, data.folders, data.topics)
  //   newState.foldersIndex = buildContentsIndex(newState.publications, newState.folders, newState.topics)
  // }

  return newState
}