import React from 'react'
import classNames from 'classnames'

import styles from './Grid.module.scss'

type Props = {
  frequency: number;
  width: number;

  className?: string;
}

const renderLines = (frequency: number, width: number) => {
  let lines = []

  for (let x = frequency, i = 1; x < width; x += frequency, i ++) {
    lines.push(<line key={i} fill="none" x1={x} y1="0" x2={x} y2="100%"/>)
  }
  
  return lines;
}

export const Grid = ({frequency, width, className}:Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={classNames(styles.Grid, className)}>
      <g>
        {renderLines(frequency, width)}
      </g>
    </svg>
  )
}