import React from 'react';
import {
  IonInput,
  IonItem,
  IonContent,
  IonPage,
  IonList,
  IonTextarea,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonHeader,
  IonButton,
  isPlatform,
  IonFooter
} from '@ionic/react';
import {ROUTES} from "config";
import {ListFooter, ThemeSelect} from "components";
import {useTranslation} from "react-i18next";
import { SpecialTypeSelect } from 'components/SpecialTypeSelect/SpecialTypeSelect';
import {CategoryType} from "components/Timeline/utils";
import {useSelector} from "react-redux";
import {getCategories} from "redux/selectors";

export const CategoryForm: React.FC = () => {

  const { t } = useTranslation('CategoryForm')
  const categories:CategoryType[] | null = useSelector(getCategories)

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={ROUTES.TIMELINE} text={t('common:CANCEL')} />
          </IonButtons>
          <IonTitle>{t('TITLE')}</IonTitle>
          {isPlatform('ios') &&
            <IonButtons slot="end">
              <IonButton disabled>
                {t('common:ADD')}
              </IonButton>
            </IonButtons>
          }
        </IonToolbar>
      </IonHeader>
      <IonContent className="outer-content">
        <IonList>
          {/*<IonListHeader>*/}
          {/*  Dates*/}
          {/*</IonListHeader>*/}
          <IonItem>
            <IonInput placeholder={t('INPUT.TITLE')}/>
          </IonItem>
          <IonItem>
            <IonTextarea placeholder={t('INPUT.DESCRIPTION')}/>
          </IonItem>
        </IonList>

        <IonList>
          <SpecialTypeSelect type={null}/>
        </IonList>
        <ListFooter>
          {t('INPUT.TYPE.HINT')}
        </ListFooter>

        <IonList>
          <ThemeSelect categories={categories}/>
        </IonList>

      </IonContent>
      {isPlatform("android") &&
      <IonFooter>
        <IonToolbar>
          <IonButtons className={"toolbar-buttons"}>
            <IonButton routerDirection="back" routerLink={ROUTES.TIMELINE}>
              {t('common:CANCEL')}
            </IonButton>
            <IonButton disabled>
              {t('common:SAVE')}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
      }
    </IonPage>
)};