import React, {useEffect, useRef, useState} from 'react';
import classNames from "classnames";

import {
  IonButton,
  IonButtons, IonContent,
  IonHeader, IonIcon,
  IonItem,
  IonLabel, IonList, IonListHeader,
  IonModal,
  IonNote, IonRadio, IonRadioGroup, IonSearchbar, IonTitle,
  IonToolbar,
} from "@ionic/react";

import { PeriodType, CategoryType } from "../Timeline/utils";

import styles from './PeriodSelect.module.scss'
import { getCategoryPik } from "icons/ionic";
import {useTranslation} from "react-i18next";

type Props = {
  periods: PeriodType[] | null;
  categories: CategoryType[] | null;
  period?: string;
};

export const PeriodSelect = ({ period, categories, periods}: Props) => {

  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [ currentPeriod, setCurrentPeriod] = useState<PeriodType | undefined>(periods ? periods.find((el: PeriodType) => el.id === period) : undefined)
  const [ currentCategory, setCurrentCategory] = useState<CategoryType | undefined>(categories && currentPeriod ? categories.find((el: CategoryType) => el.id === currentPeriod.category) : undefined)

  useEffect(
    () => setCurrentCategory(categories && currentPeriod ? categories.find((el: CategoryType) => el.id === currentPeriod.category) : undefined),
    [categories, currentPeriod]
  )

  const searchBar = useRef<HTMLIonSearchbarElement>(null);

  //TODO: Replace with onIonModalDidPresent @see https://github.com/ionic-team/ionic/issues/19923
  // useEffect(() => {
  //   if (showModal && searchBar.current) {
  //     searchBar.current.setFocus();
  //   }
  // }, [showModal])

  const { t } = useTranslation('MemoryForm')

  return (
    <>
      <IonItem button detail onClick={() => setShowModal(true)}>
        <IonLabel>{t('INPUT.PERIOD.TITLE')}</IonLabel>

        {/*{currentPeriod && <IonIcon icon={getCategoryPik(currentPeriod.type as string, circle)} slot="end" size="small" color={currentPeriod.theme} className={styles.colorIcon} />}*/}
        <IonNote slot="end" className={styles.note} color={currentCategory ? currentCategory.theme : "medium"}>
          <IonLabel className={classNames(styles.label, currentCategory && `theme-${currentCategory.theme}`)}>
            {currentPeriod ? currentPeriod.name : t('common:CHOOSE')}
          </IonLabel>
        </IonNote>
      </IonItem>


      <IonModal isOpen={showModal} onDidDismiss={e => setShowModal(false)} onDidPresent={() => searchBar.current && searchBar.current.setFocus()}>
        <IonHeader translucent>
          <IonToolbar>
            <IonTitle>{t('INPUT.PERIOD.MODAL_TITLE')}</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setShowModal(false)}>{t('common:CLOSE')}</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent scrollEvents fullscreen className="outer-content">
          <IonHeader>
            <IonToolbar>
              <IonSearchbar ref={searchBar} placeholder={t('common:SEARCH')} value={searchText} onIonChange={e => setSearchText(e.detail.value as string)}/>
            </IonToolbar>
          </IonHeader>
          <IonRadioGroup>
            {categories && categories.map((category: CategoryType) => {
              const periodsOf = periods ? periods.filter((period: PeriodType) => period.category === category.id) : undefined;
              const pik = getCategoryPik(category.type as string, null)

              return periodsOf && periodsOf.length ? (
                <React.Fragment key={category.id}>
                  <IonListHeader className={classNames(styles.listHeader, `theme-${category.theme}`)}>
                    {pik !== null && <IonIcon icon={pik} size="small" color={category.theme} className={styles.colorIcon} />}
                    {category.name}
                  </IonListHeader>
                  <IonList>
                    {periodsOf.map((period: PeriodType) => {
                      return (
                        <IonItem key={period.id} className={styles.option}>
                          {/*<IonIcon slot="start" icon={getCategoryPik(category.type as string, circle)} size={"small"} color={category.theme} className={styles.colorIcon} />*/}
                          <IonLabel className={classNames(styles.label, `theme-${category.theme}`)}>
                            <h2>{period.name}</h2>
                            {/*{category.type && <p>{t('INPUT.CATEGORY.APPLIED')}{t(`enum:CATEGORY_TYPE.${category.type}`)}</p>}*/}
                          </IonLabel>
                          <IonRadio slot="end" onSelect={() => setCurrentPeriod(period)} value={period.id} {...(currentPeriod && currentPeriod.id === period.id && {checked: true})}/>
                        </IonItem>
                      )})}
                  </IonList>
                </React.Fragment>
              ) : null})
            }
          </IonRadioGroup>
        </IonContent>
      </IonModal>
    </>
  );
};