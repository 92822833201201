import * as React from 'react';
import {IonItem, IonLabel, IonList, IonNote} from "@ionic/react";
import {ListFooter} from "../ListFooter";

type Props = {

}

export const ShareInput = (props: Props) => {
  return (
    <>
    <IonList>
      <IonItem button detail>
        <IonLabel>Share with</IonLabel>
        <IonNote slot="end">None</IonNote>
      </IonItem>
    </IonList>
    <ListFooter>
      Mention friends in this life period or share it with everyone in your public timeline
    </ListFooter>
    </>
  );
};