import React from 'react'
import classNames from "classnames";
import {CategoryType} from "components/Timeline/utils";

import styles from './Categories.module.scss'
import {Offset} from "components/Timeline/Timeline";

type Props = {
  categories: (CategoryType & { offset?: Offset })[],
  scrollTop: number,
}

export const Categories = ({categories, scrollTop}: Props) => {

  return (
    <ul className={styles.Categories} style={{transform: `translateY(-${scrollTop}px)`}}>
      {categories.map(category => (
        (!!category.offset && !!category.offset.height && <li
          className={classNames(
            styles.overlay,
            `theme-${category.theme}`
          )}
          key={category.id}
          style={{
            height: category.offset && category.offset.height ? `${category.offset.height}px` : "100px"
          }}
        >
          <span className={styles.title}>{category.name}</span>
          <div id={`periods-overlay-${category.id}`}/>
        </li>)
      ))}
    </ul>
  )
}