import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from 'translations/translations.en.json'
import ru from 'translations/translations.ru.json'

import LanguageDetector from 'i18next-browser-languagedetector';

const options = {
  resources: { en, ru },
  // whitelist: ["en", "ru"],
  // lng: "en",
  fallbackLng: {
    'uk': ['ru'],
    'default': ['en']
  },

  debug: true,

  // keySeparator: false, // we do not use keys in form messages.welcome

  interpolation: {
    escapeValue: false // react already safes from xss
  },

  detection: {
    caches: []
  }
}

export function initTranslations(savedLanguage: string | null) {


  if (savedLanguage) {
    return i18n
      .use(initReactI18next) // passes i18n down to react-i18next
      .init({...options, lng: savedLanguage});
  } else {
    return i18n
      // detect user language
      // learn more: https://github.com/i18next/i18next-browser-languageDetector
      .use(LanguageDetector)
      .use(initReactI18next) // passes i18n down to react-i18next
      .init(options);
  }
}