import React, {useEffect, useRef, useState} from 'react'
import classNames from "classnames";
import {getYearWidth, MemoryType, PeriodStickerPosition, PeriodType, YEAR_TIME} from "components/Timeline/utils";
import {Memory} from "components/Timeline/Memory";
import {PeriodSticker} from "components/Timeline/overlays";
import {TimelineHoldEvent} from "components/Timeline/HoldMenu";
import {useLongPress} from "utils";

import styles from './Period.module.scss'

type Props = {
  period: PeriodType,
  years: Array<number>,
  zoom: number,
  memories: Array<MemoryType>;
  scrollLeft: number;
  scrollTo: (params:{x?:number, y?: number}) => void;
  onPeriodClick: () => void;
  onPeriodHold: (e:Event) => void;
  onMemoryClick: (memory: MemoryType) => void;
  onMemoryHold: (e: TimelineHoldEvent) => void;
  currentPeriod: PeriodType | null;
  currentMemory: MemoryType | null;
  isScrolling: boolean;
}

type PeriodPosition = {
  width: number,
  left: number,
  right: number,
  long: boolean
}

export const getPeriodPosition = (period: PeriodType, years: Array<number>, zoom: number) => {

  const yearWidth = getYearWidth(zoom);
  const periodStartYear = period.startDate.getFullYear();
  const periodStartYearFirstDay = new Date(
    `${periodStartYear}-01-01`
  ).getTime();

  const left =
    years.indexOf(periodStartYear) * yearWidth +
    (yearWidth * (period.startDate.getTime() - periodStartYearFirstDay)) /
    YEAR_TIME;

  const width =
    (yearWidth * (period.endDate.getTime() - period.startDate.getTime())) /
    YEAR_TIME;

  const long = width > window.innerWidth;

  return { left, width, right: left + width, long };
}

const getStickerPosition = (position: PeriodPosition | null, scrollLeft: number, titleWidth: number) => {
  const {innerWidth: windowWidth} = window;
  const scrollRight = scrollLeft + windowWidth;

  if (!position) return PeriodStickerPosition.NONE

  switch (true) {
    case (scrollLeft > position.right - 10):
      return PeriodStickerPosition.LEFT;
    case (scrollLeft > position.right - 35):
      return PeriodStickerPosition.ALMOST_LEFT;

    case (scrollRight < position.left + 10):
      return PeriodStickerPosition.RIGHT;
    case (scrollRight < position.left + 35):
      return PeriodStickerPosition.ALMOST_RIGHT;

    case (scrollLeft > position.left - 5 && scrollLeft < position.right - (titleWidth + 10)):
      return PeriodStickerPosition.INSIDE;

    // case (scrollLeft > position.left - 10 && scrollRight < position.right + 10):
    //   if (scrollLeft - position.left > position.right - scrollRight)
    //     return PeriodStickerPosition.INSIDE_RIGHT;
    //   else return PeriodStickerPosition.INSIDE_LEFT;

    // default:
    //   if (scrollRight - position.right - 10 >= position.left - scrollLeft)
    //     return PeriodStickerPosition.NONE_RIGHT
    //   else return PeriodStickerPosition.NONE_LEFT

    default:
      return PeriodStickerPosition.NONE
  }
}

const getPeriodScrollToPosition = ({left, right, width, long}: PeriodPosition, sticker: PeriodStickerPosition) => {
  if (long) {
    if (sticker === PeriodStickerPosition.LEFT || sticker === PeriodStickerPosition.ALMOST_LEFT) {
      return right - window.innerWidth / 2;
    } else if (sticker === PeriodStickerPosition.RIGHT || sticker === PeriodStickerPosition.ALMOST_RIGHT) {
      return left - window.innerWidth / 2;
    }
  } else return left - window.innerWidth / 2 + width / 2;
}

export const Period = ({
  period,
  zoom,
  years,
  memories,
  scrollLeft,
  scrollTo,
  onPeriodClick,
  onPeriodHold,
  onMemoryClick,
  onMemoryHold,
  currentPeriod,
  currentMemory,
  isScrolling
}:Props) => {

  const onHoldWatcher = useLongPress(onPeriodHold, 500);

  const titleRef = useRef<HTMLDivElement>(null)
  const periodRef = useRef<HTMLDivElement>(null)

  const [position, setPeriodPosition] = useState<PeriodPosition | null>(null);
  useEffect(() => {
    setPeriodPosition(getPeriodPosition(period, years, zoom))
  }, [period, years, zoom]);

  const [sticker, setSticker] = useState<PeriodStickerPosition>(PeriodStickerPosition.NONE);

  useEffect(() => {
    setSticker(getStickerPosition(position, scrollLeft, titleRef.current ? titleRef.current.clientWidth : 0))
  }, [position, scrollLeft]);

  const stickersRoot = document.getElementById('periods-overlay-'+period.category);

  return position ? (
    <div className={classNames(styles.Period)}>
      <div
        className={classNames(styles.view, period.end === "now" && styles.now)}
        style={{
          left: `${position.left}px`,
          width: `${position.width}px`
        }}
        onClick={onPeriodClick}
        // onClick={() => scrollTo({y: periodRef.current ? periodRef.current.offsetTop : undefined})}
        ref={periodRef}
        {...onHoldWatcher}
      >
        <div ref={titleRef} className={classNames(
          styles.title,
          (sticker === PeriodStickerPosition.NONE_LEFT) && styles.left,
          (sticker === PeriodStickerPosition.NONE_RIGHT) && styles.right,
          (sticker !== PeriodStickerPosition.NONE_LEFT && sticker !== PeriodStickerPosition.NONE_RIGHT && sticker !== PeriodStickerPosition.NONE) && styles.hidden,
          (sticker === PeriodStickerPosition.NONE && isScrolling) && styles.over
        )}>{period.name}</div>
      </div>
      {
        memories.map(memory =>
          <Memory
            memory={memory}
            years={years}
            zoom={zoom}
            key={memory.name}
            onMemoryClick={(e) => {onMemoryClick(memory); e.nativeEvent.stopPropagation()} }
            onMemoryHold={(e:Event) => onMemoryHold({nativeEvent: e, contextId: memory.id, contextType: "memory"})}
            currentMemory={currentMemory}
          />
        )
      }
      <PeriodSticker
        type={sticker}
        stickersRoot={stickersRoot}
        previewTitle={period.name}
        previewCounter={memories.length}
        onPreviewClick={() => scrollTo({x: getPeriodScrollToPosition(position, sticker)})}
        isScrolling={isScrolling}
      />
    </div>
  ) : null
}