import React from 'react';
import {
  IonInput,
  IonItem,
  IonLabel,
  IonContent,
  IonPage,
  IonList,
  IonListHeader,
  IonDatetime,
  IonTextarea,
  IonToolbar, IonButtons, IonBackButton, IonTitle, IonHeader, IonToggle, IonButton,
  isPlatform, IonFooter, IonNote, IonRange
} from '@ionic/react';
import {ROUTES} from "config";
import {ListFooter, ActionIcon, ShareInput, CustomModalProps} from "components";
import {useTranslation} from "react-i18next";
import {HelpModal} from "pages/Home/Help";
import {PeriodSelect} from "components/PeriodSelect";
import {CategoryType, PeriodType} from "components/Timeline/utils";
import {useSelector} from "react-redux";
import {getCategories, getPeriods} from "redux/selectors";

const renderHelpModal = (modal:CustomModalProps) => <HelpModal sectionId="timelines" topicId="blurred_mode" {...modal}/>


export const MemoryForm: React.FC = () => {

  const categories:CategoryType[] | null = useSelector(getCategories)
  const periods:PeriodType[] | null = useSelector(getPeriods)
  const { t } = useTranslation('MemoryForm');

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={ROUTES.TIMELINE} text={t('common:CANCEL')} />
          </IonButtons>
          <IonTitle>{t('TITLE')}</IonTitle>
          {isPlatform('ios') &&
            <IonButtons slot="end">
              <IonButton disabled>
                {t('common:ADD')}
              </IonButton>
            </IonButtons>
          }
        </IonToolbar>
      </IonHeader>
      <IonContent className="outer-content">
        {/*<IonListHeader>*/}
        {/*  <IonLabel>Description</IonLabel>*/}
        {/*</IonListHeader>*/}
        <IonList>
          <IonItem>
            {/*<IonLabel position="stacked">Period name</IonLabel>*/}
            <IonInput placeholder={t('INPUT.TITLE')}></IonInput>
          </IonItem>
          <IonItem>
            {/*<IonLabel position="stacked">Short description</IonLabel>*/}
            <IonTextarea placeholder={t('INPUT.DESCRIPTION')}></IonTextarea>
          </IonItem>
        </IonList>

        <IonList>
          <PeriodSelect categories={categories} periods={periods}/>
        </IonList>

        {/*<IonListHeader>*/}
        {/*  <IonLabel>Dates</IonLabel>*/}
        {/*</IonListHeader>*/}
        <IonList>
          <IonItem>
            <IonLabel>{t('INPUT.DATE.TITLE')}</IonLabel>
            <IonDatetime displayFormat="MM DD YYYY" placeholder={t('INPUT.DATE.PLACEHOLDER')}/>
          </IonItem>
          <IonItem>
            <IonLabel>
              {t('INPUT.BLURRED')}
              <ActionIcon type="help" fill={true} modal={renderHelpModal}/>
            </IonLabel>
            <IonToggle />
          </IonItem>
        </IonList>

        <IonListHeader>
          <IonLabel>{t('INPUT.SIGNIFICANCE.TITLE')}</IonLabel>
        </IonListHeader>
        <IonList lines="full">
          <IonItem>
            {/*<IonGrid className="ion-grid-input">*/}
            {/*  <IonRow className="ion-align-items-center">*/}
            {/*    <IonCol className="ion-text-center" size="2">*/}
            {/*      <IonLabel>Month</IonLabel>*/}
            {/*    </IonCol>*/}
            {/*    <IonCol size="8">*/}
            {/*      <IonRange min={1} max={4} step={1} snaps={true} ticks={true} color="primary"/>*/}
            {/*    </IonCol>*/}
            {/*    <IonCol className="ion-text-center" size="2">*/}
            {/*      <IonLabel>Lifetime</IonLabel>*/}
            {/*    </IonCol>*/}
            {/*  </IonRow>*/}
            {/*  <IonRow>*/}
            {/*    <IonCol size="8" offset="2">*/}
            {/*      <IonLabel color="medium" className="ion-text-center">month</IonLabel>*/}
            {/*    </IonCol>*/}
            {/*  </IonRow>*/}
            {/*</IonGrid>*/}

            <IonRange min={1} max={4} step={1} snaps={true} ticks={true} color="primary">
              <IonLabel slot="start">{t('INPUT.SIGNIFICANCE.MIN')}</IonLabel>
              <IonLabel slot="end">{t('INPUT.SIGNIFICANCE.MAX')}</IonLabel>
            </IonRange>
          </IonItem>
        </IonList>
        <ListFooter>
          <IonLabel>{t('INPUT.SIGNIFICANCE.HINT')}</IonLabel>
        </ListFooter>

        <IonList>
          <IonItem detail button>
            <IonLabel>{t('INPUT.ANNIVERSARY.TITLE')}</IonLabel>
            <IonNote slot="end">{t('common:NONE')}</IonNote>
          </IonItem>
        </IonList>
        <ListFooter>
          <IonLabel>
            {t('INPUT.ANNIVERSARY.HINT')}
            <ActionIcon fill={false} type="info" action={() => {alert("Anniversary is ...")}}/>
          </IonLabel>
        </ListFooter>

        <ShareInput/>


      </IonContent>
      {isPlatform("android") &&
      <IonFooter>
        <IonToolbar>
          <IonButtons className={"toolbar-buttons"}>
            <IonButton routerDirection="back" routerLink={ROUTES.TIMELINE}>
              {t('common:CANCEL')}
            </IonButton>
            <IonButton disabled>
              {t('common:SAVE')}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
      }
    </IonPage>
)}