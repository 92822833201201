import React from 'react'
import classNames from "classnames";

import {
  getYearBaseWidth,
  getYearWidth,
  MONTHS_TEXT
} from "components/Timeline/utils";
import { Grid, Line } from "components/Timeline/overlays";

import styles from './Years.module.scss'

type Props = {
  years: Array<number>,
  zoom: number,
  scrollLeft: number,
  today?: number;
  detailed: boolean;
}

export const Years = ({years, zoom, scrollLeft, today, detailed}: Props) => {

  const yearWidth = getYearWidth(zoom);
  const yearBaseWidth = getYearBaseWidth();

  return (
    <div
      className={classNames(styles.Years, styles[`zoom-${zoom}`], detailed && styles.detailed)}
      >
      {detailed && <Line position={"50%"} className={styles.seekerLine}/>}
      <ul
        className={styles.inner}
        style={{transform:`translateX(-${scrollLeft}px)`}}
      >
        <Grid
          frequency={yearBaseWidth / (zoom + 1)}
          width={years.length * yearWidth}
          className={classNames(styles.grid, styles[`zoom-${zoom}`])}
        />
        {
          years.map(y => (
            <li key={y} style={{ width: `${yearWidth}px` }}>
              <span className={styles.year}>{y}</span>
              {zoom === 2 &&
              MONTHS_TEXT.map(m => (
                <span className={styles.month} key={m}>
                  {m}
                </span>
              ))}
            </li>
          ))}
         <Line position={today} className={styles.todayLine}/>

        </ul>
    </div>
  )
}