import * as React from 'react';
import styles from './ActionIcon.module.scss'
import {IonButton, IonIcon} from "@ionic/react";
import {
  close,
  closeCircle,
  closeCircleOutline,
  helpCircle,
  helpCircleOutline,
  informationCircle,
  informationCircleOutline
} from "ionicons/icons";
import classNames from "classnames";
import {useState} from "react";

export interface CustomModalProps {
  isOpen: boolean;
  dismiss: () => void;
}

// interface ModalAction {
//   modal: React.ReactElement<React.FunctionComponent<CustomModalProps>>
//   // props: {[index:string]:any;
// }

interface Props {
  color?: string
  type?: "help" | "info" | "close" | "cross"
  fill?: boolean;
  size?: "large" | "small";
  action?: ((e:React.MouseEvent) => void) | string;
  modal?: React.FC<CustomModalProps>
}

export const ActionIcon: React.FC<Props> = ({
    color,
    type,
    fill,
    size,
    action,
    modal
}) => {

  const [showModal, setShowModal] = useState(false);

  let i;
  switch (type) {
    case "help":
      i = fill ? helpCircle : helpCircleOutline;
      break
    case "info":
      i = fill ? informationCircle : informationCircleOutline;
      break
    case "close":
      i = fill ? closeCircle : closeCircleOutline;
      break
    case "cross":
      i = close;
  }

  return (
      ((action || modal) ? <div className={styles.ActionIcon} onClick={e => e.stopPropagation()}><IonButton
        className={classNames(styles.button, type && styles[type])}
        fill="clear" size={size}
        onClick={typeof action === "function" ? action: (modal ? (e:React.MouseEvent) => setShowModal(true) : undefined)}
        routerLink={typeof action === "string" ? action: undefined}
      >
        <IonIcon icon={i} color={color}/>
      </IonButton>
      {modal && modal({isOpen: showModal, dismiss: () => setShowModal(false)})}
      </div>: null)
  );
};

ActionIcon.defaultProps = {
  color: "medium",
  type: "help",
  fill: false,
  size: "small"
}