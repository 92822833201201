
import * as TimelineActions from './TimelineActions'
import * as SettingsActions from './SettingsActions'
import * as ContentActions from './ContentActions'

export {
  TimelineActions,
  SettingsActions,
  ContentActions
}

// timeline action types
export const SET_SCROLL = 'SET_SCROLL';
export const SET_ZOOM = 'SET_ZOOM';
export const SET_CURRENT_PERIOD = 'SET_CURRENT_PERIOD';
export const SET_CURRENT_MEMORY = 'SET_CURRENT_MEMORY';
export const SET_CURRENT_VIEW = 'SET_CURRENT_VIEW';
export const UNSET_CURRENT_VIEW = 'UNSET_CURRENT_VIEW';

// settings action types
export const SET_TAPTIC_FEEDBACK = 'SET_TAPTIC_FEEDBACK';
export const SET_LANGUAGE = 'SET_LANGUAGE';

// contents action types
export const GET_CONTENTS_REQUEST = 'GET_CONTENTS_REQUEST';
export const GET_CONTENTS_ERROR = 'GET_CONTENTS_ERROR';
export const GET_CONTENTS_SUCCESS = 'GET_CONTENTS_SUCCESS';


