import React, { useEffect, useState } from 'react'
import classNames from "classnames";
import { CategoryType } from "components/Timeline/utils";

import styles from './CategorySticker.module.scss'
import {Offset} from "components/Timeline/Timeline";

type Props = {
  categories:  (CategoryType & { offset?: Offset })[]
  scrollTop: number;
}

type State = {
  isVisible: boolean;
  currentCategory: CategoryType & { offset?: Offset } | null;
}

//TODO: check positioning
const shouldShowCategoriesSticker = (categories: (CategoryType & { offset?: Offset })[], scrollTop: number) => {
  const currentCategoryIndex =
    //@ts-ignore
    categories.findIndex(c => scrollTop + 47 <= c.offset.top) - 1;

  const isVisible =
    categories[currentCategoryIndex] &&
    (scrollTop <
    //@ts-ignore
    categories[currentCategoryIndex].offset.top + categories[currentCategoryIndex].offset.height - 120);

  return {
    isVisible,
    currentCategory: categories[currentCategoryIndex],
  }
}

export const CategorySticker = ({categories, scrollTop}: Props) => {
  const [categoriesSticker, setCategoriesSticker] = useState<State>({
    isVisible: false,
    currentCategory: null,
  });


  useEffect(() => {
    setCategoriesSticker(shouldShowCategoriesSticker(categories, scrollTop))
  }, [categories, scrollTop]);

  const { isVisible, currentCategory } = categoriesSticker

  return (
    <div
      className={classNames(
        styles.CategoriesSticker,
        //@ts-ignore
        currentCategory && currentCategory.theme && `theme-${currentCategory.theme}`,
        !isVisible && styles.hidden
      )}
    >
      {currentCategory ? currentCategory.name : ""}
    </div>
  )
}