// @flow
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {IonIcon, IonItem, IonLabel, IonList, IonPopover} from "@ionic/react";
import {
  generateMenuOptions,
  MenuContextType,
  MenuOption
} from "components/Timeline/HoldMenu/options";

export type TimelineHoldEvent = {nativeEvent: Event, contextId: string, contextType: MenuContextType, asd?:string}

type Props = {
  holdEvent?: TimelineHoldEvent | null;
  onDismiss: () => void
};

export const HoldMenu = ({ holdEvent, onDismiss }: Props) => {

  const [menuOptions, setMenuOptions] = useState<MenuOption[]>([])
  const popover = useRef(null)
  const { t } = useTranslation('menu')

  useEffect(() => {
    if (holdEvent) setMenuOptions(generateMenuOptions(holdEvent.contextType, holdEvent.contextId))
  },[holdEvent])

  return (
    <IonPopover
      isOpen={!!holdEvent}
      event={holdEvent ? holdEvent.nativeEvent : undefined}
      onDidDismiss={onDismiss}
      translucent={true}
      ref={popover}
    >
      <IonList
        lines="full"
        //@ts-ignore
        onClick={() => {if (popover.current.overlay) popover.current.overlay.dismiss()}}>
        {menuOptions.map((option: MenuOption, index: number) => (
          <IonItem button detail={false} key={index} onClick={option.action || undefined} routerLink={option.url || undefined} className={option.className || undefined}>
            <IonLabel>{t(option.title)}</IonLabel>
            <IonIcon icon={option.icon} color="medium" size="small" slot="end" />
          </IonItem>
        ))}
      </IonList>
    </IonPopover>
  );
};