import React from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'
import {PeriodStickerPosition} from "components/Timeline/utils";

import styles from './PeriodSticker.module.scss'

type Props = {
  stickersRoot: any;
  type: PeriodStickerPosition
  onPreviewClick?: () => void;
  previewTitle: string;
  previewCounter: number;
  isScrolling: boolean;
}


export const PeriodSticker = ({
    type,
    stickersRoot,
    previewTitle,
    previewCounter,
    onPreviewClick,
    isScrolling
}:Props) => {
  return stickersRoot ? ReactDOM.createPortal((
    <div className={classNames(
      styles.PeriodSticker,
      styles[type],
      isScrolling && styles.scrolling
    )}>
      <div
        className={styles.preview}
        onClick={onPreviewClick}
      >{previewCounter ? previewCounter : type === PeriodStickerPosition.RIGHT || type === PeriodStickerPosition.ALMOST_RIGHT ? ">" : "<"}</div>
      <div className={styles.title}>
        {previewTitle}
      </div>
    </div>
  ), stickersRoot) : null
}