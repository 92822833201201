import * as React from 'react';
import {useState} from "react";
import {
  IonButton,
  IonContent,
  IonModal,
} from "@ionic/react";


export const TestModal = () => {

  const [showModal, setShowModal] = useState(false);
  const [modalClass, setModalClass] = useState("my-modal");

  return (
    <>
      <IonButton onClick={() => setShowModal(true)}>Show modal</IonButton>
      <IonModal isOpen={showModal} cssClass={modalClass} showBackdrop={false}>
        <IonContent>
          <IonButton onClick={() => setShowModal(false)}>Close modal</IonButton>
          <IonButton onClick={() => setModalClass("new-modal-class")} color="secondary">Change css class</IonButton>

        </IonContent>
      </IonModal>
    </>
  );
};