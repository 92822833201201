import * as React from 'react';
import styles from './ListFooter.module.scss'

interface Props {
  children: any
};
export const ListFooter = (props: Props) => {
  return (
    <div className={styles.ListFooter} {...props}>
      {props.children}
    </div>
  );
};