import React, {useState} from 'react';
import classNames from "classnames";

import {
  IonButton,
  IonButtons, IonContent,
  IonHeader, IonIcon,
  IonItem,
  IonLabel, IonList, IonListHeader,
  IonModal,
  IonNote, IonRadio, IonRadioGroup, IonTitle,
  IonToolbar,
} from "@ionic/react";

import {CategoryType} from "../Timeline/utils";

import styles from './CategorySelect.module.scss'
import {circle, getCategoryPik} from "icons/ionic";
import {useTranslation} from "react-i18next";

//TODO: replace with real global category instance
const globalCategory:CategoryType = {
  id: 'global',
  name: "Global",
  theme: 'global',
  order: 0
}

type Props = {
  categories: CategoryType[] | null;
  category?: string;
};

export const CategorySelect = ({ category, categories}: Props) => {

  const [showModal, setShowModal] = useState(false);
  const [currentCategory, setCurrentCategory] = useState<CategoryType | undefined>(categories ? categories.find((el: CategoryType) => el.id === category) : undefined);
  const { t } = useTranslation('PeriodForm')

  return (
    <>

      <IonItem button detail onClick={() => setShowModal(true)}>
        <IonLabel>{t('INPUT.CATEGORY.TITLE')}</IonLabel>

        {currentCategory && <IonIcon icon={getCategoryPik(currentCategory.type as string, circle)} slot="end" size="small" color={currentCategory.theme} className={styles.colorIcon} />}
        <IonNote slot="end" className={styles.note} color={currentCategory ? currentCategory.theme : "medium"}>
          <IonLabel className={classNames(styles.label, currentCategory && `theme-${currentCategory.theme}`)}>
            {currentCategory ? currentCategory.name : t('common:CHOOSE')}
          </IonLabel>
        </IonNote>
      </IonItem>

      <IonModal isOpen={showModal} onDidDismiss={e => setShowModal(false)}>
        <IonHeader translucent>
          <IonToolbar>
            <IonTitle>{t('INPUT.CATEGORY.MODAL_TITLE')}</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setShowModal(false)}>{t('common:CLOSE')}</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent scrollEvents fullscreen className="outer-content">
          <IonRadioGroup>
            <IonList>
              <IonItem className={styles.option}>
                <IonIcon slot="start" icon={circle} size={"small"} color="global" className={styles.colorIcon} />
                <IonLabel className={styles.label}>{globalCategory.name}</IonLabel>
                <IonRadio slot="end" onSelect={() => setCurrentCategory(globalCategory)} value={globalCategory.id} {...(currentCategory && currentCategory.id === globalCategory.id && {checked: true})}/>
              </IonItem>
            </IonList>
            <IonListHeader>{t('INPUT.CATEGORY.TITLE_LIST')}</IonListHeader>
            {categories && <IonList>
                {categories.map((category: CategoryType) => {
                  return (
                  <IonItem key={category.id} className={styles.option}>
                    <IonIcon slot="start" icon={getCategoryPik(category.type as string, circle)} size={"small"} color={category.theme} className={styles.colorIcon} />
                    <IonLabel className={classNames(styles.label, `theme-${category.theme}`)}>
                      <h2>{category.name}</h2>
                      {category.type && <p>{t('INPUT.CATEGORY.APPLIED')}{t(`enum:CATEGORY_TYPE.${category.type}`)}</p>}
                    </IonLabel>
                    <IonRadio slot="end" onSelect={() => setCurrentCategory(category)} value={category.id} {...(currentCategory && currentCategory.id === category.id && {checked: true})}/>
                  </IonItem>
                )})}
            </IonList>}
          </IonRadioGroup>
        </IonContent>
      </IonModal>
    </>
  );
};

// CategorySelect.defaultProps = {
//   category: defaultCategories[0].id
// }