import {
  Plugins,
  HapticsImpactStyle
} from '@capacitor/core';

const { Haptics } = Plugins;

class HapticsAll {
  impact(style = HapticsImpactStyle.Heavy) {
    Haptics.impact({
      style: style
    });
  }

  impactMedium() {
    this.impact(HapticsImpactStyle.Medium);
  }

  impactLight() {
    this.impact(HapticsImpactStyle.Light);
  }

  vibrate() {
    Haptics.vibrate();
  }

  selectionStart() {
    Haptics.selectionStart();
  }

  selectionChanged() {
    Haptics.selectionChanged();
  }

  selectionEnd() {
    Haptics.selectionEnd();
  }
}

export const taptics = new HapticsAll()