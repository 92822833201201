import React, {useState} from 'react';
import classNames from "classnames";
import {useTranslation} from "react-i18next";

import {
  IonButton,
  IonButtons, IonContent,
  IonHeader, IonIcon,
  IonItem,
  IonLabel, IonList,
  IonModal,
  IonNote, IonRadio, IonRadioGroup, IonTitle,
  IonToolbar
} from "@ionic/react";
import { TIMELINE } from 'config'

import {checkmarkCircleOutline} from "ionicons/icons";
import {CategoryType} from "../Timeline/utils";
import {ListFooter} from "../ListFooter";

import styles from './ThemeSelect.module.scss'
import {circle} from "icons/ionic";

const themes = Object.keys(TIMELINE.THEMES);

type Props = {
  theme: string,
  categories: CategoryType[] | null;
};

export const ThemeSelect = ({ theme, categories }: Props) => {

  const [showModal, setShowModal] = useState(false);
  const [currentTheme, setCurrentTheme] = useState(theme);
  const { t } = useTranslation('CategoryForm')

  return (
    <>
      <IonItem button detail onClick={() => setShowModal(true)}>
        <IonLabel>{t('INPUT.THEME.TITLE')}</IonLabel>
        <IonIcon icon={circle} slot="end" size="small" color={currentTheme} className={styles.colorIcon} />
        <IonNote slot="end" color={currentTheme} className={styles.note}>
          <IonLabel className={classNames(styles.label, styles[`theme-${currentTheme}`])}>
            {TIMELINE.THEMES[currentTheme]}</IonLabel>
        </IonNote>
      </IonItem>
      <IonModal isOpen={showModal} onDidDismiss={e => setShowModal(false)}>
        <IonHeader translucent>
          <IonToolbar>
            <IonTitle>{t('INPUT.THEME.MODAL_TITLE')}</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setShowModal(false)}>{t('common:CLOSE')}</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent scrollEvents fullscreen>
          <IonList>
            <IonRadioGroup onIonChange={e => setCurrentTheme(e.detail.value)}>
              {themes.map((theme: string) => {
                const used = !!(categories && categories.find((el:CategoryType) => el.theme === theme))
                return (
                <IonItem key={theme} color={theme}>
                  <IonLabel>{`${TIMELINE.THEMES[theme]}`}</IonLabel>
                  <IonRadio slot="end" value={theme} {...(currentTheme === theme && {checked: true})} disabled={used}/>
                  {used && <IonIcon slot="end" icon={checkmarkCircleOutline} size="small"/>}
                </IonItem>
              )})}
            </IonRadioGroup>
          </IonList>
          <ListFooter>
            {t('INPUT.THEME.HINT')}
          </ListFooter>
        </IonContent>
      </IonModal>
    </>
  );
};

ThemeSelect.defaultProps = {
  theme: Object.keys(TIMELINE.THEMES)[0]
}