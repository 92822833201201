import {
  GET_CONTENTS_REQUEST,
  GET_CONTENTS_ERROR,
  GET_CONTENTS_SUCCESS
} from "./";

import { api } from 'services/OfflineStorage'

export function getContents() {
  console.log('dispatched getContents');
  
  return (dispatch: any) => {

    dispatch({
      type: GET_CONTENTS_REQUEST
    })

    return api.getContents()
      .then((data:any) => {

        if (data === null) { // request was canceled manually
          dispatch({
            type:  GET_CONTENTS_ERROR,
          })
          return null
        }

        dispatch({
          type:  GET_CONTENTS_SUCCESS,
          payload: data
        })

      })
      .catch((error:Error) => {
        dispatch({
          type:  GET_CONTENTS_ERROR,
          payload: error
        })
        // throw error
      })
  }
}