import React, {useEffect, useState} from 'react'
import classNames from "classnames";
import { Icon } from "components";

import styles from './Splash.module.scss'

import { Plugins } from '@capacitor/core';

const { SplashScreen } = Plugins;

type Props = {
  ready: boolean;
  nativeDelay?: number;
  innerDelay?: number;
  animationDelay?: number;
}

export const Splash = ({ ready , nativeDelay, innerDelay, animationDelay}: Props) => {
  const [shouldRender, setShouldRender] = useState<boolean>(true);
  const [disappear, letDisappear] = useState<boolean>(false);

  useEffect(() => {
    if (ready) {
      setTimeout(() => SplashScreen.hide({fadeOutDuration: 0}), nativeDelay);
      setTimeout(() => letDisappear(true), (nativeDelay || 0) + (innerDelay || 0))
    }
    if (disappear) {
      setTimeout(() => setShouldRender(false), animationDelay)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready, disappear]);

  return (
    (shouldRender ? <div className={classNames(styles.Splash, disappear && styles.animate)}>
      <Icon type="selfory"/>
    </div> : null)
  )
}

Splash.defaultProps = {
  ready: false,
  nativeDelay: 2000,
  innerDelay: 200,
  animationDelay: 2000
};