import { SET_TAPTIC_FEEDBACK, SET_LANGUAGE } from "../actions";

export type SettingsReduxState = {
  taptic: boolean;
  language: string | null;
}

const initialState:SettingsReduxState = {
  taptic: true,
  language: null
};

//@ts-ignore
export default function(state = initialState, action) {
  switch (action.type) {
    case SET_TAPTIC_FEEDBACK: {
      const { taptic } = action.payload;
      return {
        ...state,
        taptic
      };
    }

    case SET_LANGUAGE: {
      const { language } = action.payload;
      return {
        ...state,
        language
      };
    }
    // case TOGGLE_TODO: {
    //   const { id } = action.payload;
    //   return {
    //     ...state,
    //     byIds: {
    //       ...state.byIds,
    //       [id]: {
    //         ...state.byIds[id],
    //         completed: !state.byIds[id].completed
    //       }
    //     }
    //   };
    // }
    default:
      return state;
  }
}
