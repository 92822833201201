import React, { RefObject } from "react";
import PropTypes from 'prop-types'
import classNames from "classnames";
import { withIonLifeCycle } from '@ionic/react'

import {
  YEAR_TIME,
  processMemories,
  processPeriods,

  PeriodType, MemoryType, CategoryType, getYearWidth, getYearBaseWidth
} from "./utils"

import { Categories, YearSticker, Grid, CategorySticker, Line, ScrollBars, Years } from "components/Timeline/overlays";

import { Period, getPeriodPosition } from 'components/Timeline/Period';
import { getMemoryPosition } from "components/Timeline/Memory";

import { ScrollParamsType } from "containers";
import { taptics } from "utils";

import { HoldMenu } from "components/Timeline/HoldMenu";
import { TimelineHoldEvent } from "components/Timeline/HoldMenu/HoldMenu";

import styles from "./Timeline.module.scss"
import {TIMELINE} from "config";

export enum TimelineOverlays {
  YEARS = "YEARS",
  YEARS_STICKER = "YEARS_STICKER",
  CATEGORIES = "CATEGORIES",
  CATEGORIES_STICKER = "CATEGORIES_STICKER",
  SCROLLBARS = "SCROLLBARS",
  GRID = "GRID",
  TODAY = "TODAY"
}

export type Offset = {
  left: number;
  top: number;
  height: number;
}

type TimelineOverlaysProp = {
  [TimelineOverlays.YEARS]?: boolean;
  [TimelineOverlays.YEARS_STICKER]?: boolean;
  [TimelineOverlays.CATEGORIES]?: boolean;
  [TimelineOverlays.CATEGORIES_STICKER]?: boolean;
  [TimelineOverlays.SCROLLBARS]?: boolean;
  [TimelineOverlays.GRID]?: boolean;
  [TimelineOverlays.TODAY]?: boolean;
}

export const defaultTimelineOverlays = {
  [TimelineOverlays.YEARS]: true,
  [TimelineOverlays.YEARS_STICKER]: true,
  [TimelineOverlays.CATEGORIES]: true,
  [TimelineOverlays.CATEGORIES_STICKER]: true,
  [TimelineOverlays.SCROLLBARS]: true,
  [TimelineOverlays.GRID]: true,
  [TimelineOverlays.TODAY]: true,
}

type TimelineProps = {
  categories: Array<CategoryType>,
  periods: Array<PeriodType>,
  memories: Array<MemoryType>,
  // onPeriodClick: (x: any) => void
  currentPeriod: PeriodType | null;
  currentMemory: MemoryType | null;
  setCurrentView: (period?: PeriodType | null, memory?: MemoryType | null) => void;
  // setCurrentMemory: (memory: MemoryType | null) => void;
  // setCurrentPeriod: (period: PeriodType | null) => void;

  scrollLeft: number;
  scrollTop: number;
  isScrolling: boolean;
  zoom: number;
  tapticFeedback: boolean;

  scrollTo: (params: ScrollParamsType) => void;
  // lockTo: (x: number | null, y?: number) => void;

  timelineRef: RefObject<HTMLDivElement>;
  containerRef: RefObject<HTMLDivElement>

  overlays: TimelineOverlaysProp;
}

interface State {
  years: Array<number>;
  periods: Array<PeriodType>;
  memories: Array<MemoryType>;
  categories: (CategoryType & { offset?: Offset })[];
  // currentPeriod: PeriodType | null;
  // currentMemory: MemoryType | null;
  holdEvent?: TimelineHoldEvent | null;
}

class Timeline extends React.Component<TimelineProps> {
  static propTypes = {
    onPeriodClick: PropTypes.func
  };

  static defaultProps = {
    // categories: defaultCategories,
    // periods: defaultPeriods,
    // memories: defaultMemories,
    overlays: defaultTimelineOverlays
  };

  categoriesRefs: Array<RefObject<HTMLDivElement> | null> = []

  state: State = {
    years: [],
    categories: [],
    periods: [],
    memories: [],
    // currentPeriod: null,
    // currentMemory: null
  };

  startZoom: number = 0;

  constructor(props: TimelineProps) {
    super(props);

    const {categories, periods, memories} = props;

    this.state = {
      ...processPeriods(periods),
      ...processMemories(memories),
      categories: this.calculateOffset(categories, periods),
      // currentPeriod: null,
      // currentMemory: null
    };

    this.categoriesRefs = this.state.categories.map((category, item) => {
      return React.createRef<HTMLDivElement>();
    }, {});
  }

  get yearBaseWidth() {
    //@ts-ignore
    return getYearBaseWidth()
  }

  get yearWidth() {
    //@ts-ignore
    return getYearWidth(this.props.zoom)
  }

  onPeriodClick = (period: PeriodType) => {
    let currentPeriod = (period !== this.props.currentPeriod) ? period : null;

    if (currentPeriod) {
      const { zoom  } = this.props
      const { years } = this.state
      const { left } = getPeriodPosition(currentPeriod, years, zoom);

      this.props.scrollTo({x: left - window.innerWidth / 2, /*lock: true,*/ force: true})
    } else {
      this.props.scrollTo({lock: false})
    }

    this.props.setCurrentView(currentPeriod);
    // this.setState({currentPeriod});
  }

  onElementHold = (holdEvent:TimelineHoldEvent) => {
    const { tapticFeedback, isScrolling } = this.props;

    if (!isScrolling && holdEvent) {
      if (tapticFeedback) taptics.impactMedium();

      this.setState({holdEvent});
    }
  }

  onMemoryClick = (memory: MemoryType) => {
    const { currentPeriod, setCurrentView } = this.props;
    let currentMemory = (memory !== this.props.currentMemory) ? memory : null;

    if (currentMemory) {
      const { zoom } = this.props
      const { years } = this.state
      const { left } = getMemoryPosition(currentMemory, years, zoom);

      this.props.scrollTo({x: left - window.innerWidth / 2, /*lock: true,*/ force: true})
    } else {
      this.props.scrollTo({lock: false})
    }

    // this.setState({currentMemory});
    setCurrentView(currentPeriod, currentMemory);
  }


  // getPositionedCategories = (categories: CategoryType[]):CategoryType[] => {
  //   // const { categories } = this.state;
  //
  //   return categories.map(this.getCategoryOffset);
  // }
  //
  // getCategoryOffset = (category: CategoryType, index: number):CategoryType => {
  //   const ref = this.categoriesRefs[index];
  //
  //   if (ref && ref.current)
  //     return {
  //       ...category,
  //       height: ref.current.clientHeight,
  //       top: ref.current.offsetTop
  //     };
  //   else return category;
  // }

  /**
   * TODO:
   */
  calculateOffset = (categories: CategoryType[], periods: PeriodType[]) => {
    // const { yearWidth, state: { categories, years } } = this

    // let offsetAll:Array<CategoryOffset|PeriodOffset|MemoryOffset> = []
    let allHeight = 0;
    return categories.map((category, i) => {
      const periodsOf = periods.filter(p => p.category === category.id);

      // periodsOf.map((period, k) => {
        //offsetAll.push({id: period.id, y: categoryY + TIMELINE.PERIOD_SIZE * k, x: 0, width: yearWidth})
      // });
      //offsetAll.push({id: category.id, y: categoryY, height: periods.length * TIMELINE.PERIOD_SIZE + TIMELINE.CATEGORY_PADDING})
      const height = periodsOf.length ? periodsOf.length * TIMELINE.PERIOD_SIZE + TIMELINE.CATEGORY_PADDING : 0;
      const top = allHeight;
      allHeight += height;

      return {
        ...category,
        offset: { left: 0, top, height }
      }
    });

    //allHeight -=  TIMELINE.CATEGORY_PADDING;

    // this.setState({offset: offsetAll, canvas: { width: years.length * yearWidth, height: allHeight}})
  }


  getTodayPosition():number {
      const {
          state: { years },
          yearWidth
      } = this;
      const thisYear = new Date().getFullYear();
      const thisYearStart = new Date(`${thisYear}-01-01`).getTime();

      return yearWidth *
          (years.indexOf(thisYear) +
              (new Date().getTime() - thisYearStart) / YEAR_TIME);
  }

  getElementSize = (ref: RefObject<HTMLElement>) => {
    if (ref.current) {
      return {width: ref.current.clientWidth, height: ref.current.clientHeight}
    } else {
      return null
    }

  }

  renderPeriods(categoryId: string) {
    const {periods, years, memories } = this.state
    const { zoom, scrollLeft, currentPeriod, currentMemory, isScrolling } = this.props
    return periods
      .filter(p => p.category === categoryId)
      .map(period => (
        <Period
          period={period}
          memories={memories.filter(m => m.period === period.id)}
          zoom={zoom}
          years={years}
          key={`${period.name}--${period.start}--${period.end}`}
          scrollLeft={scrollLeft}
          scrollTo={this.props.scrollTo}
          onPeriodClick={() => this.onPeriodClick(period)}
          onPeriodHold={(e:Event) => this.onElementHold({nativeEvent: e, contextId: period.id, contextType: "period"})}
          currentPeriod={currentPeriod}
          onMemoryClick={this.onMemoryClick}
          onMemoryHold={this.onElementHold}
          currentMemory={currentMemory}
          isScrolling={isScrolling}
        />
      ));
  }

  renderCategories() {
    // const { currentPeriod } = this.state;

    return this.state.categories.map((category, i) => (
      (!!category.offset && !!category.offset.height && <div className={classNames(
        styles.category,
        "theme-"+category.theme,
        // currentPeriod && currentPeriod.category === category.id && "current"
      )} key={category.id}>
        {this.renderPeriods(category.id)}
        {/*TODO: {this.renderMemories(c.name)}*/}
      </div>)
    ));
  }

  componentDidMount() {
    // this.getPositionedCategories()
  }

  ionViewDidEnter() {
    console.log('ion view did enter');

    if (this.state.categories) this.setState({
      categories: this.calculateOffset(this.state.categories, this.state.periods)
    })
  }

  componentDidUpdate(prevProps: TimelineProps, prevState: State) {
    // if (prevState.currentPeriod !== this.state.currentPeriod) {
    //   //setTimeout(() => this.getPositionedCategories(), 500)
    // }

    if (prevProps.periods !== this.props.periods) {
      this.setState({
        ...processPeriods(this.props.periods)
      })
    }
    if (prevProps.memories !== this.props.memories) {
      this.setState({
        ...processMemories(this.props.memories)
      })
    }
    if (this.props.categories && prevProps.categories !== this.props.categories) {
      this.setState({
        categories: this.calculateOffset(this.props.categories, this.props.periods)
      })
    }
  }

  render() {
    const {
      yearWidth, yearBaseWidth,
      state: { years, categories },
      props: { zoom, scrollLeft, scrollTop, isScrolling, overlays, currentPeriod, currentMemory  }
    } = this;
    return (
        <>
          {overlays[TimelineOverlays.YEARS] &&
            <Years years={years} zoom={zoom} scrollLeft={scrollLeft} today={this.getTodayPosition()} detailed={!!(currentPeriod || currentMemory)}/>
          }
          {overlays[TimelineOverlays.YEARS_STICKER] &&
            <YearSticker years={years} zoom={zoom} scrollLeft={scrollLeft} detailed={!!(currentPeriod || currentMemory)}/>
          }
          <div
            className={styles.Timeline}
            ref={this.props.containerRef}
          >
            {(currentPeriod || currentMemory) && <Line position={"50%"} className={styles.seekerLine}/>}
            <div
              className={classNames(styles.canvas, `zoom-${zoom}`)}
              style={{
                width: `${yearWidth * this.state.years.length}px`,
                transform: `translate(-${scrollLeft}px, -${scrollTop}px)`}}
              ref={this.props.timelineRef}
            >
              {overlays[TimelineOverlays.GRID] &&
                <Grid
                  frequency={yearBaseWidth / (zoom + 1)}
                  width={this.state.years.length * yearWidth}
                  className={classNames(styles.grid, styles[`zoom-${zoom}`])}
                />
              }
              {this.renderCategories()}
              {overlays[TimelineOverlays.TODAY] &&
                <Line position={this.getTodayPosition()} className={styles.todayLine}/>
              }
            </div>
          </div>
          {overlays[TimelineOverlays.CATEGORIES] &&
            <Categories categories={categories} scrollTop={scrollTop}/>
          }
          {overlays[TimelineOverlays.CATEGORIES_STICKER] &&
            <CategorySticker categories={categories} scrollTop={scrollTop}/>
          }
          {overlays[TimelineOverlays.SCROLLBARS] &&
            <ScrollBars
              containerSize={this.getElementSize(this.props.containerRef)}
              timelineSize={this.getElementSize(this.props.timelineRef)}
              scrollLeft={scrollLeft}
              scrollTop={scrollTop}
              isScrolling={isScrolling}/>
          }
          <HoldMenu
            holdEvent={this.state.holdEvent}
            onDismiss={() => this.setState({holdEvent: null})}
          />
        </>
    )
  }
}

export default withIonLifeCycle(Timeline)
