import {add, create, eye, gitCompare, share} from "ionicons/icons";
import {ROUTES} from "config";

import styles from "components/Timeline/HoldMenu/HoldMenu.module.scss";

export type MenuContextType = "memory" | "period" | "category"

export interface MenuOption {
  action?: () => void;
  url?: string;
  icon: string;
  title: string;
  className?:string;
}

export const generateMenuOptions = (type: MenuContextType, contextId: string) => {
  switch (type) {
    case "memory": return generateMemoryOptions(contextId);
    case "period": return generatePeriodOptions(contextId);
    case "category": return generateCategoryOptions(contextId);
    default: return [];
  }
};

const generatePeriodOptions = (contextId: string):MenuOption[] => [
  {
    title: 'HOLD.PREVIEW',
    icon: eye,
    action: () => {alert(`Preview period width ID#${contextId}`)}
  },
  {
    title: 'HOLD.SHARE',
    url: '',
    icon: share
  },
  {
    title: 'HOLD.EDIT',
    url: `${ROUTES.TIMELINE}${ROUTES.PERIOD}/${contextId}`,
    icon: create,
  },
  {
    title: 'HOLD.ADD_MEMORY',
    url: `${ROUTES.TIMELINE}${ROUTES.MEMORY}`,
    icon: add,
    className: styles.separateAction
  },
];

const generateMemoryOptions = (contextId: string):MenuOption[] => [
  {
    title: 'HOLD.VIEW',
    url: `${ROUTES.TIMELINE}${ROUTES.MEMORY}/${contextId}`,
    icon: eye
  },
  {
    title: 'HOLD.SHARE',
    url: '',
    icon: share
  },
  {
    title: 'HOLD.COMPARE',
    url: '',
    icon: gitCompare
  },
  {
    title: 'HOLD.EDIT',
    url: `${ROUTES.TIMELINE}${ROUTES.MEMORY}/${contextId}`,
    icon: create,
    className: styles.separateAction
  }
]

const generateCategoryOptions = (contextId: string):MenuOption[] => [
  {
    title: 'HOLD.EDIT',
    url: `${ROUTES.CATEGORY}${ROUTES.MEMORY}/${contextId}`,
    icon: create
  }
];