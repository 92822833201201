import { createStore, applyMiddleware, compose, StoreEnhancer } from "redux";
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage'
import { devToolsEnhancer } from 'redux-devtools-extension/logOnlyInProduction'

import rootReducer from "../reducers";

const persistConfig = {
  key: 'root',
  version: 1,
  whitelist: ['timeline', 'settings'],
  // migrate: createMigrate(migrations, { debug: false }),
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const enhancer:StoreEnhancer = compose(
  applyMiddleware(
    thunk
    //TODO: loggerMiddleWare
  ),
  devToolsEnhancer({name: "SelforyApp"})
)

export default () => {
  let store = createStore(persistedReducer, enhancer)
  let persistor = persistStore(store)
  return { store, persistor }
}