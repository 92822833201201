import * as React from 'react';

import {useTranslation} from "react-i18next";
import {Route, RouteComponentProps} from "react-router";
import {
  IonBackButton, IonButton,
  IonButtons, IonContent,
  IonHeader, IonIcon, IonModal, IonPage, IonRouterOutlet, IonSearchbar,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import {HelpSectionType, HelpTopicType, helpTopics} from "pages/Home/Help/topics";
import {useEffect, useState} from "react";
import { TopicText, TopicsSection, TopicsIndex } from "./Contents";
import {ROUTES} from "config";
import {bookmark} from "ionicons/icons";

import styles from './Help.module.scss'

type State = {
  currentTopic: HelpTopicType | null;
  currentSection: HelpSectionType | null;
}

interface Props extends RouteComponentProps<{
  section: string;
  id: string;
}> {

}

export const HelpPage: React.FC<RouteComponentProps> = ({match}) => {
  return (
    <IonRouterOutlet id="help">
      <Route exact path={match.url} component={Help} />
      <Route path={`${match.url}/:section/:id`} component={HelpTopic} />
    </IonRouterOutlet>
  );
};

export const Help: React.FC<Props> = (
  { match: { params: { section: sectionId, id: topicId }}}
  ) => {

  const { t } = useTranslation('Help');

  //TODO: Introduce referrer link for Back
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={ROUTES.HOME} text={t('BACK')} />
          </IonButtons>
          <IonTitle>{t('TITLE')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader>
          <IonToolbar>
            <IonSearchbar placeholder={t('SEARCH')}/>
          </IonToolbar>
        </IonHeader>

        <TopicsIndex/>

      </IonContent>
    </IonPage>
  );
};


export const HelpTopic: React.FC<Props> = (
  { match: { params: { section: sectionId, id: topicId }}}
  ) => {

  const [{ currentSection, currentTopic }, setCurrentTopic ] = useState<State>({ currentTopic: null, currentSection: null})
  useEffect(() => {
    const currentSection = helpTopics.find(section => section.id === sectionId) || null;
    const currentTopic = currentSection ? currentSection.topics.find(topic => topic.id === topicId) || null : null;

    setCurrentTopic({currentTopic, currentSection})
  },[topicId, sectionId])

  const { t } = useTranslation('Help');

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={ROUTES.HELP} text={t('TITLE')} />
          </IonButtons>
          <IonTitle>{t(currentSection ? `help:${currentSection.id}.TITLE` : 'common:NOT_FOUND')}</IonTitle>
          <IonButtons slot="end">
            <IonButton>
              <IonIcon slot="icon-only" icon={bookmark}/>
            </IonButton>
          </IonButtons>
        </IonToolbar>

      </IonHeader>
      <IonContent>
        {currentTopic && currentSection ?
          <>
            <TopicText topic={currentTopic} section={currentSection}/>
            <TopicsSection section={currentSection} exclude={currentTopic} title={t('MORE')}/>
          </>
          : <div className={styles.NotFound}>{t('common:NOT_FOUND')}</div>
        }
      </IonContent>
    </IonPage>
  );
};

type ModalProps = {
  sectionId: string;
  topicId: string;
  isOpen: boolean;
  dismiss: () => void;
}

export const HelpModal: React.FC<ModalProps> = ({ sectionId, topicId, isOpen, dismiss}) => {

  const [{ currentSection, currentTopic }, setCurrentTopic ] = useState<State>({ currentTopic: null, currentSection: null})
  useEffect(() => {
    const currentSection = helpTopics.find(section => section.id === sectionId) || null;
    const currentTopic = currentSection ? currentSection.topics.find(topic => topic.id === topicId) || null : null;

    setCurrentTopic({currentTopic, currentSection})
  },[topicId, sectionId])

  const { t } = useTranslation('Help');

  return (
    <IonModal isOpen={isOpen} onDidDismiss={dismiss}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={dismiss}>{t('common:CLOSE')}</IonButton>
          </IonButtons>
          <IonTitle>{t(currentSection ? 'TITLE' : 'common:NOT_FOUND')}</IonTitle>
          <IonButtons slot="end">
            <IonButton>
              <IonIcon slot="icon-only" icon={bookmark}/>
            </IonButton>
          </IonButtons>
        </IonToolbar>

      </IonHeader>
      <IonContent>
        {currentTopic && currentSection ?
            <TopicText topic={currentTopic} section={currentSection}/>
          : <div className={styles.NotFound}>{t('common:NOT_FOUND')}</div>
        }
      </IonContent>
    </IonModal>
  );
};

