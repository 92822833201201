//import { VISIBILITY_FILTERS } from "../constants";

//@ts-ignore
import {ReduxState} from "redux/reducers";

export const getTimelineState = (store:ReduxState) => store.timeline;

// //@ts-ignore
export const getCurrentTimelineView = (store:ReduxState) => {
  const { currentPeriod, currentMemory } = getTimelineState(store)
  return { currentPeriod, currentMemory }
}

// //@ts-ignore
// export const getTodoById = (store, id) =>
//   getSettingsState(store) ? { ...getSettingsState(store).byIds[id], id } : {};

/**
 * example of a slightly more complex selector
 * select from store combining information from multiple reducers
 */
// export const getTodos = store =>
//   getTodoList(store).map(id => getTodoById(store, id));
//
// export const getTodosByVisibilityFilter = (store, visibilityFilter) => {
//   const allTodos = getTodos(store);
//   switch (visibilityFilter) {
//     case VISIBILITY_FILTERS.COMPLETED:
//       return allTodos.filter(todo => todo.completed);
//     case VISIBILITY_FILTERS.INCOMPLETE:
//       return allTodos.filter(todo => !todo.completed);
//     case VISIBILITY_FILTERS.ALL:
//     default:
//       return allTodos;
//   }
// };
