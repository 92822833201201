import React from 'react';
import {useTranslation} from "react-i18next";
import {
  IonInput,
  IonItem,
  IonLabel,
  IonContent,
  IonPage,
  IonList,
  IonDatetime,
  IonTextarea,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonHeader,
  IonToggle,
  IonButton,
  isPlatform,
  IonFooter,
  IonNote,
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/react';
import {ROUTES} from "config";
import {CategorySelect, ActionIcon, ListFooter, ShareInput, CustomModalProps} from "components";
import {HelpModal} from "pages/Home/Help";
import {useSelector} from "react-redux";
import { getCategories } from "redux/selectors";
import {CategoryType} from "components/Timeline/utils";

const renderHelpModal = (modal:CustomModalProps) => <HelpModal sectionId="timelines" topicId="blurred_mode" {...modal}/>


export const PeriodForm: React.FC = () => {

  const { t } = useTranslation('PeriodForm')
  const categories:CategoryType[] | null = useSelector(getCategories)
  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={ROUTES.TIMELINE} text={t('common:CANCEL')} />
          </IonButtons>
          <IonTitle>{t('TITLE')}</IonTitle>
          {isPlatform('ios') &&
          <IonButtons slot="end">
            <IonButton disabled>
              {t('common:ADD')}
            </IonButton>
          </IonButtons>
          }
        </IonToolbar>
      </IonHeader>
      <IonContent className="outer-content">

        {/*<IonListHeader>*/}
        {/*  Dates*/}
        {/*</IonListHeader>*/}
        <IonList>
          <IonItem>
            <IonInput placeholder={t('INPUT.TITLE')}/>
          </IonItem>
          <IonItem>
            <IonTextarea placeholder={t('INPUT.DESCRIPTION')}/>
          </IonItem>
        </IonList>

        <IonList>
          <CategorySelect categories={categories}/>
        </IonList>

        <IonList>
          <IonItem lines="full">
            <IonGrid className="ion-grid-input">
              <IonRow>
                <IonCol>
                    <IonDatetime displayFormat="MM DD YYYY" placeholder={t('INPUT.DATE_START')}/>
                </IonCol>
                <IonCol className="ion-text-end">
                    <IonDatetime displayFormat="MM DD YYYY" placeholder={t('INPUT.DATE_END')}/>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>
          {/*<IonItem lines="full" class="ion-text-center">*/}
          {/*  <IonLabel color="medium">Select dates to see period preview</IonLabel>*/}
          {/*</IonItem>*/}
          <IonItem>
            <IonLabel>
              {t('INPUT.BLURRED')}
              <ActionIcon type="help" fill={true} modal={renderHelpModal}/>
            </IonLabel>
            <IonToggle />
          </IonItem>
        </IonList>

        <IonList>
          <IonItem button detail>
            <IonLabel>{t('INPUT.ANNIVERSARY.TITLE')}</IonLabel>
            <IonNote slot="end">{t('common:NONE')}</IonNote>
          </IonItem>
        </IonList>
        <ListFooter>
          <IonLabel>
            {t('INPUT.ANNIVERSARY.HINT')}
            <ActionIcon type="info" fill={false} action={() => {alert("Anniversary is ...")}}/>
          </IonLabel>
        </ListFooter>

        <ShareInput/>

      </IonContent>


      {isPlatform("android") &&
      <IonFooter>
        <IonToolbar>
          <IonButtons className={"toolbar-buttons"}>
            <IonButton routerDirection="back" routerLink={ROUTES.TIMELINE}>
              {t('common:CANCEL')}
            </IonButton>
            <IonButton disabled>
              {t('common:SAVE')}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
      }
    </IonPage>
)};