import React from 'react';
import {
  IonContent, IonHeader, IonPage, IonTitle, IonToolbar,
} from '@ionic/react';


import {AddMenu, Icon} from "components";

import styles from './TimelinePage.module.scss'
import {useSelector} from "react-redux";
import {getContentState, getCurrentTimelineView} from "redux/selectors";
import classNames from "classnames";
import TimelineListView from "containers/TimelineListView";

type Props = {
  history: any;
}

export const TimelinePage = ({ history }:Props) => {

  const { currentPeriod, currentMemory } = useSelector(getCurrentTimelineView)
  const { categories, periods, memories } = useSelector(getContentState)

  return (
    <IonPage className={styles.TimelinePage}>
      <IonHeader className={classNames(styles.header, (currentPeriod || currentMemory) && styles.detailed)}>
        <IonToolbar mode="ios" className={styles.toolbar}>
          <IonTitle className={styles.title}>{currentPeriod && currentPeriod.name}</IonTitle>
        </IonToolbar>
        <Icon type="triangle"/>

      </IonHeader>
      {/*<IonContent forceOverscroll={false} scrollX={false} scrollY={false} fullscreen>*/}
      <IonContent>
          {/*<TimelineContainer/>*/}
          <TimelineListView categories={categories} periods={periods} memories={memories}/>
          <AddMenu history={history}/>
      </IonContent>
    </IonPage>
  );
};