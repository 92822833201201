import React from 'react';
import { Provider } from 'react-redux'
import { configureStore } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'

import ReactDOM from 'react-dom';
import SelforyApp from './App';
import * as serviceWorker from './serviceWorker';
import { Splash } from "components";

// webview debug redirect
// const LOCAL_HOST = 'localhost'
// if (window.location.host !== `${LOCAL_HOST}:3000`) {
//   let href = prompt('[ DEBUG MODE ] Redirect to:', `http://${LOCAL_HOST}/#/home`)
//   if (href) {
//     //@ts-ignore
//     setTimeout(() => window.location.href = href, 1)
//   }
// }

const { store, persistor } = configureStore()

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      {(ready) => (
        <>
          <Splash ready={ready}/>
          <SelforyApp ready={ready}/>
        </>
      )}
    </PersistGate>
  </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
