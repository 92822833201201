import React, {useEffect, useState} from 'react'
import classNames from "classnames";

import styles from './ScrollBars.module.scss'

type ElementSize = {width: number, height: number}

type Props = {
  scrollLeft: number;
  scrollTop: number;
  isScrolling: boolean;

  containerSize: ElementSize | null,
  timelineSize: ElementSize | null

  className?: string;
}

type ScrollBarsOffset = {
  width: number;
  height: number;
  left: number;
  top: number;
}

const getScrollBarsOffset = (scrollLeft: number, scrollTop: number, containerSize: ElementSize | null, timelineSize: ElementSize | null) => {
  if (containerSize && timelineSize) {
    return {
      width: containerSize.width * containerSize.width / timelineSize.width,
      height: containerSize.height * containerSize.height / timelineSize.height,
      left: scrollLeft * containerSize.width / timelineSize.width,
      top: scrollTop * containerSize.height / timelineSize.height
    }
  } else return {
    width: 200,
    height: 200,
    left: 0,
    top: 0
  };
}

export const ScrollBars = ({className, scrollLeft, scrollTop, isScrolling, containerSize, timelineSize}: Props) => {
  const [offset, setOffset] = useState<ScrollBarsOffset>({
    width: 200,
    height: 200,
    left: 20,
    top: 20
  });

  useEffect(() => {
    if (containerSize && timelineSize) setOffset(getScrollBarsOffset(scrollLeft, scrollTop, containerSize, timelineSize))
  }, [scrollLeft, scrollTop, containerSize, timelineSize]);

  return (offset && offset.top && offset.left) ? (
     <svg xmlns="http://www.w3.org/2000/svg" className={classNames(styles.ScrollBars, className, isScrolling && styles.scrolling)}>
      <line x1="0" y1="100%" x2="100%" y2="100%" className={styles.background}/>
      <line x1="100%" y1="0" x2="100%" y2="100%" className={styles.background}/>
      <line x1="100%" y1={offset.top} x2="100%" y2={offset.top + offset.height}/>
      <line x1={offset.left} y1="100%" x2={offset.left + offset.width} y2="100%"/>
    </svg>
  ) : null;
}