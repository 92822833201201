import React, {useState} from 'react'
import {IonActionSheet, IonFab, IonFabButton, IonIcon} from "@ionic/react";
import {add} from "ionicons/icons";
import {ROUTES} from "config";
import {useTranslation} from "react-i18next";

type Props = {
  history: any
}

export const AddMenu = ({ history }: Props) => {

  const { t } = useTranslation('menu');
  const [showActionSheet, setShowActionSheet] = useState(false);

  return (
    <>
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton onClick={() => setShowActionSheet(true)}>
          <IonIcon icon={add} />
        </IonFabButton>
      </IonFab>
      <IonActionSheet
        isOpen={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        buttons={[{
          text: t('ADD.MEMORY'),
          handler: () => {
            history.push(`${ROUTES.TIMELINE}${ROUTES.MEMORY}`)
          }
        }, {
          text: t('ADD.PERIOD'),
          handler: () => {
            history.push(`${ROUTES.TIMELINE}${ROUTES.PERIOD}`)
          }
        }, {
          text: t('ADD.TIMELINE'),
          handler: () => {
            history.push(`${ROUTES.TIMELINE}${ROUTES.CATEGORY}`)
          }
        }, {
          text: t('common:CANCEL'),
          role: 'cancel',
        }]}
      >
      </IonActionSheet>
    </>
  )
}