import temp_contents from '_temp/contents.json'

interface Api {
  getContents: () => void
}

// TODO: Offline SQLite storage
class OfflineStorage implements Api {
    getContents() {
      return new Promise((resolve, reject) => {
        setTimeout(() => resolve(temp_contents), 2000);
      })
    }
}

export const api = new OfflineStorage()