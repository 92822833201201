import { GET_CONTENTS_REQUEST, GET_CONTENTS_ERROR, GET_CONTENTS_SUCCESS } from "../actions";

import {CategoryType, MemoryType, PeriodType} from "components/Timeline/utils";

import {processLoadedContent} from "utils";

export type ContentReduxState = {
  fetching: boolean;
  categories: CategoryType[] | null,
  periods: PeriodType[] | null,
  memories: MemoryType[] | null,
  error: Error | null;
}

const initialState:ContentReduxState = {
  fetching: false,
  categories: null,
  periods: null,
  memories: null,
  error: null
};

// todo: remove ts-ignore
//@ts-ignore
export default function(state = initialState, action) {

  switch (action.type) {
    case GET_CONTENTS_REQUEST:
      return {...state, categories: null, periods: null, memories: null, fetching: true, error: null}
    case GET_CONTENTS_ERROR:
      return {...state, categories: null, periods: null, memories: null, fetching: false, error: action.payload}
    case GET_CONTENTS_SUCCESS:
      return processLoadedContent(state, action.payload)

    // case TOGGLE_TODO: {
    //   const { id } = action.payload;
    //   return {
    //     ...state,
    //     byIds: {
    //       ...state.byIds,
    //       [id]: {
    //         ...state.byIds[id],
    //         completed: !state.byIds[id].completed
    //       }
    //     }
    //   };
    // }
    default:
      return state;
  }
}
