import React, {useState} from 'react';

import {
  IonButton,
  IonButtons, IonContent,
  IonHeader, IonIcon,
  IonItem,
  IonLabel, IonList, IonListHeader,
  IonModal,
  IonNote, IonRadio, IonRadioGroup, IonTitle,
  IonToolbar,
} from "@ionic/react";

import { CategorySpecialType } from "../Timeline/utils";

import styles from './SpecialTypeSelect.module.scss'
import {circle, getCategoryPik} from "icons/ionic";
import {useTranslation} from "react-i18next";
import {ActionIcon, CustomModalProps} from "components/ActionIcon";
import {ListFooter} from "components/ListFooter";
import {HelpModal} from "pages/Home/Help";

const renderHelpModal = (modal:CustomModalProps) => <HelpModal sectionId="timelines" topicId="special_types" {...modal}/>

type Props = {
  type: CategorySpecialType | null
};


export const SpecialTypeSelect = ({ type }: Props) => {

  const [showModal, setShowModal] = useState(false);
  const [currentType, setCurrentType] = useState<CategorySpecialType | null>(type);
  const { t } = useTranslation('CategoryForm')

  return (
    <>
      <IonItem button detail onClick={() => setShowModal(true)}>
        <IonLabel>
          {t('INPUT.TYPE.TITLE')}
          <ActionIcon type="help" fill={true} modal={renderHelpModal}/>
        </IonLabel>
        {currentType
          ?<>
          <IonIcon icon={getCategoryPik(currentType as string, null)} slot="end" size="small" color="medium" className={styles.typeIcon} />
          <IonNote slot="end" className={styles.note} color={currentType ? "dark" : "medium"}>
            <IonLabel className={styles.label}>{t(`enum:CATEGORY_TYPE.${currentType}`)}</IonLabel>
          </IonNote>
          </>
          :<IonNote slot="end">{t('common:NONE')}</IonNote>
        }
      </IonItem>
      
      <IonModal isOpen={showModal} onDidDismiss={e => setShowModal(false)}>
        <IonHeader translucent>
          <IonToolbar>
            <IonTitle>{t('INPUT.TYPE.TITLE')}</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setShowModal(false)}>{t('common:CLOSE')}</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent scrollEvents fullscreen className="outer-content">
          <IonRadioGroup>
            <IonList lines="full">
              <IonItem key={0}>
                <IonLabel className={styles.label}>
                  <h2>{t(`common:NONE`)}</h2>
                </IonLabel>
                <IonRadio slot="end" onSelect={() => setCurrentType(null)} value={null} {...(currentType ? {} : {checked: true})}/>
              </IonItem>
            </IonList>
            <ListFooter>
              <IonLabel>{t('INPUT.TYPE.HINT_NO')}</IonLabel>
            </ListFooter>
            <IonListHeader className={styles.header}>
              {t('INPUT.TYPE.TITLE_LIST')}
              {/*<ActionIcon type="help" fill={false} modal={((props:CustomModalProps) => <HelpModal sectionId="timelines" topicId="special_types" {...props}/>)}/>*/}
            </IonListHeader>
            <IonList>
                {Object.keys(CategorySpecialType).map((type: string) => (
                  <IonItem key={type} className={styles.option}>
                    <IonIcon slot="start" icon={getCategoryPik(type as string, circle)} size={"small"} color="medium" className={styles.typeIcon} />
                    <IonLabel>
                      <h2>{t(`enum:CATEGORY_TYPE.${type}`)}</h2>
                    </IonLabel>
                    <IonRadio slot="end" onSelect={() => setCurrentType(type as CategorySpecialType)} value={type} {...(currentType === type && {checked: true})}/>
                  </IonItem>
                ))}
            </IonList>
            {currentType && <ListFooter>
              <IonLabel>{t(`enum:CATEGORY_TYPE_HINT.${currentType}`)}</IonLabel>
            </ListFooter>}
          </IonRadioGroup>

        </IonContent>
      </IonModal>
    </>
  );
};