import {SET_TAPTIC_FEEDBACK, SET_LANGUAGE} from "./";
import i18n from "i18next";
import {taptics} from "utils";

export const setTapticFeedback = (taptic: boolean) => (dispatch: any) => {
  if (taptic) taptics.impactMedium();

  dispatch({
    type: SET_TAPTIC_FEEDBACK,
    payload: { taptic }
  })
};

export const setLanguage = (language: string | null) => (dispatch: any) => {
  language = language === 'auto' ? null : language;
  const lng = language || "";

  i18n.changeLanguage(lng).then(() => dispatch({
    type: SET_LANGUAGE,
    payload: { language }
  }))
}


// ({
//   type: SET_LANGUAGE,
//   payload: { language }
// });
