import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {useTranslation} from "react-i18next";

export interface SideMenuOption {
  url: string;
  icon: string;
  title: string;
}

interface MenuProps extends RouteComponentProps {
  options: SideMenuOption[];
}

const SideMenu: React.FunctionComponent<MenuProps> = ({ options }) => {

  const { t } = useTranslation('menu')

  return (
    <IonMenu side="start" menuId="home" contentId="main" swipeGesture={false}>
      <IonHeader>
        <IonToolbar color="background">
          <IonTitle>{t('SIDE.TITLE')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {options.map((option, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem routerLink={option.url} routerDirection="none">
                  <IonIcon slot="start" icon={option.icon} />
                  <IonLabel>{t(option.title)}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
}

export default withRouter(SideMenu);
