import { combineReducers } from "redux";
import settings, { SettingsReduxState } from "./settings";
import timeline, { TimelineReduxState } from "./timeline";
import content, { ContentReduxState } from "./contents";

export interface ReduxState {
  settings: SettingsReduxState,
  timeline: TimelineReduxState,
  content: ContentReduxState
}

export default combineReducers({ settings, timeline, content });
