import React from 'react';
import {
  IonButtons,
  IonMenuButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import { useTranslation } from 'react-i18next';

import {ActionIcon, Icon} from "components";

import styles from './Home.module.scss';
import { TopicsSection } from 'pages';
import {getTopicsSection} from "pages/Home/Help/topics";

export const Home: React.FC = () => {
  const { t } = useTranslation('Home');

  const notes = getTopicsSection("general")

  return (
    <IonPage className={styles.Home}>
      <IonHeader>
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <IonMenuButton menu="home" />
          </IonButtons>
          <IonTitle><Icon type="selfory"/></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard className="welcome-card">
          <ActionIcon color="medium" type="cross" size="large" action={() => {}}/>
          {/*<img src="/assets/shapes.svg" alt="" />*/}
          <IonCardHeader>
            <IonCardSubtitle>{t('GET_STARTED')}</IonCardSubtitle>
            <IonCardTitle>{t('WELCOME', { name: "Selfory"})}</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <p>
              {t('WELCOME_MESSAGE')}
            </p>
          </IonCardContent>
        </IonCard>

        {notes && <TopicsSection section={notes} title={t('NOTES')}></TopicsSection>}

      </IonContent>
    </IonPage>
  );
};