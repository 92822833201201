import {
  SET_CURRENT_MEMORY,
  SET_CURRENT_PERIOD, SET_CURRENT_VIEW,
  SET_SCROLL, SET_ZOOM, UNSET_CURRENT_VIEW
} from '../actions'
import {MemoryType, PeriodType} from "components/Timeline/utils";

export type TimelineReduxState = {
  scrollLeft: number;
  scrollTop: number;
  zoom: number;
  currentPeriod: PeriodType | null;
  currentMemory: MemoryType | null;
}

const initialState:TimelineReduxState = {
  scrollLeft: 0,
  scrollTop: 0,
  zoom: 0,
  currentPeriod: null,
  currentMemory: null
};

//@ts-ignore
export default function(state = initialState, action) {
  switch (action.type) {
    case SET_SCROLL: {
      const { scrollLeft, scrollTop } = action.payload;
      return {
        ...state,
        scrollLeft,
        scrollTop
      };
    }
    case SET_ZOOM: {
      const { zoom } = action.payload;
      return {
        ...state,
        zoom,
      };
    }
    case SET_CURRENT_PERIOD: {
      const { period } = action.payload;
      return {
        ...state,
        currentPeriod: period,
      };
    }
    case SET_CURRENT_MEMORY: {
      const { memory } = action.payload;
      return {
        ...state,
        currentMemory: memory,
      };
    }
    case SET_CURRENT_VIEW: {
      const { memory, period } = action.payload;

      return {
        ...state,
        currentMemory: memory,
        currentPeriod: period,
      };
    }
    case UNSET_CURRENT_VIEW: {
      return {
        ...state,
        currentMemory: null,
        currentPeriod: null,
      };
    }
    // case TOGGLE_TODO: {
    //   const { id } = action.payload;
    //   return {
    //     ...state,
    //     byIds: {
    //       ...state.byIds,
    //       [id]: {
    //         ...state.byIds[id],
    //         completed: !state.byIds[id].completed
    //       }
    //     }
    //   };
    // }
    default:
      return state;
  }
}
