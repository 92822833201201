//
// TODO: Remove inner utils (functions should go to /utils, constants into config etc.)
//

export const YEAR_WIDTH = 150;
export const OVERSCROLL_PADDING = 0;
export const YEAR_TIME = 365 * 24 * 60 * 60 * 1000;
export const MONTHS_TEXT = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
];
export const MONTHS_NUM = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12"
];

export enum PeriodStickerPosition {
    LEFT = "left",
    ALMOST_LEFT = "almost_left",
    RIGHT = "right",
    ALMOST_RIGHT = "almost_right",
    INSIDE = "inside",
    NONE = "none",

    INSIDE_LEFT = "inside_left",
    INSIDE_RIGHT = "inside_right",

    NONE_LEFT = "none_left",
    NONE_RIGHT = "none_right"
}

export const PERIODS_OVERLAY_ID = "periods-overlay"


export type PeriodType = {
    id: string;
    name: string;
    start: string;
    end: string;
    category: string;
    startDate: Date;
    endDate: Date;
}

export type MemoryType = {
    id: string;
    name: string;
    date: string;
    memoryDate: Date;
    category: string;
    period: string | null;
    significance: number;
    image?: string;
}

export enum CategorySpecialType  {
    relations = "relations",
    kids = "kids",
    home = "home",
    friends = "friends",
    education = "education",
    careers = "careers",
    vehicle = "vehicle",
}

export type CategoryType = {
    id: string;
    name: string;
    theme: string;
    order: number;
    type?: CategorySpecialType
    top?: number;
    left?: number;
    height?: number;
}

export const getMonths = (datetime:number) => {
    return datetime / 1000 / 60 / 60 / 24 / 30;
};

export const getYearBaseWidth = () => {
    return (window.innerWidth / 2.5);
}

export const getYearWidth = (zoom: number) => {
    return getYearBaseWidth() * 2 ** zoom;
}

export const roundStep = (number:number, increment:number, offset:number = 0):number => {
    return Math.ceil((number - offset) / increment ) * increment + offset;
}

export const processPeriods = (periods:Array<PeriodType>) => {
    const processedPeriods = periods
        .map(period => ({
            ...period,
            startDate: new Date(period.start),
            endDate: period.end === "now" ? new Date() : new Date(period.end)
            //...this.getPeriodPosition(p)
        }))
        .sort((p1, p2) => (p1.startDate > p2.startDate ? 1 : -1));
    const minYear = Math.min(
        ...processedPeriods.map(p => p.startDate.getFullYear())
    );
    const maxYear = Math.max(
        ...processedPeriods.map(p => p.endDate.getFullYear())
    );

    let years = [];
    for (let y = minYear; y <= maxYear; y++) {
        years.push(y);
    }
    years.push(new Date().getFullYear() + 1);

    // const categories = [
    //     ...new Set(processedPeriods.map(p => p.category).sort())
    // ].map(c => ({ name: c }));

    return {
        minYear,
        maxYear,
        periods: processedPeriods,
        years: years.sort(),
        currentYear: years[0],
        // categories,
    };
};

export const processMemories = (memories:Array<MemoryType>) => {
    const processedMemories = memories
        .map(memory => ({
            ...memory,
            memoryDate: new Date(memory.date)
        }))
        .sort((m1, m2) => (m1.memoryDate > m2.memoryDate ? 1 : -1));
    return {
        memories: processedMemories
    };
};

// export const processCategories = (categories: Array<CategoryType>) => {
//     const processedCategories = categories
//       .map(category => ({
//           ...category,
//             periodsLength: periods.filter(period => period.category === category.id).length
//       }))
// }